import { JetView } from "webix-jet";
import "../helpers/dtext";
import "../helpers/dtoggle";
import icons from "../helpers/icons";

export default class FormView extends JetView {
	config() {
		this._ = this.app.getService("locale")._;
		this.Styles = this.app.getService("styles");
		this.Local = this.app.getService("local");
		this.MarginX = 6;
		const ui = {
			width: 300,
			cells: [
				{
					localId: "common",
					view: "form",
					scroll: true,
					elementsConfig: {
						on: {
							onChange: this.ChangeHandler,
						},
					},
					elements: this.GetCommonConfig(),
				},
				{
					localId: "block",
					view: "form",
					scroll: true,
					elementsConfig: {
						on: {
							onChange: this.ChangeHandler,
						},
					},
					elements: this.GetBlockConfig(),
				},
				{
					localId: "link",
					view: "form",
					scroll: true,
					elementsConfig: {
						on: {
							onChange: this.ChangeHandler,
						},
					},
					elements: this.GetLinkConfig(),
				},
			],
		};

		return ui;
	}
	init() {
		this.Mode = "common";
		this.ShowForm("common");
		this.SetCommonValues();

		this.on(this.app, "diagram:syncform", item => {
			const obj =
				this.Mode == "link"
					? this.Styles.getLinkValues(item)
					: this.Styles.getBlockValues(item);
			this.Form.setValues(obj, true);
		});
		this.on(this.app, "diagram:select", (obj, link) => {
			if (obj) {
				this.Mode = link ? "link" : "block";
				this.ShowForm(this.Mode);
				if (link) this.SetLinkValues(obj);
				else this.SetBlockValues(obj);
			} else {
				this.Mode = "common";
				this.ShowForm("common");
				this.SetCommonValues();
			}
		});

		this.on(this.app, "values:defaults", () => {
			this.SetCommonValues();
		});
	}

	/**
	 * Shows form view
	 * @param name {String} - an id of a form view("common"/"block"/"link")
	 */
	ShowForm(name) {
		this.Form = this.$$(name);
		this.Form.show(null, false);
	}

	/**
	 * onChange event handler for form elements
	 * @param newV {String|Number} - a new element value
	 * @param oldV {String| Number} - a previous element values
	 * @param config {String} - handler config (if "user", value is changed by a user)
	 */
	ChangeHandler(newV, oldV, config) {
		if (config == "user") this.$scope.UpdateValue(this.config.name, newV, oldV);
	}
	/**
	 * Set values for common farm
	 */
	SetCommonValues() {
		const form = this.Form;
		this.ShowBatch(form, "common");
		form.setValues(this.Styles.getCommonValues());
	}
	/**
	 * Set values for block form
	 * @param item {object} item properties
	 */
	SetBlockValues(item) {
		const form = this.Form;
		if (!item.type || this.Local.shapes().getItem(item.type).template != "text")
			this.ShowBatch(form, "shape");
		else this.ShowBatch(form, "text");
		form.setValues(this.Styles.getBlockValues(item));
	}

	/**
	 * Set values for link form
	 * @param item {Object} link properties
	 */
	SetLinkValues(item) {
		const form = this.Form;
		this.ShowBatch(form, "link");
		form.setValues(this.Styles.getLinkValues(item));
	}

	/**
	 * Gets an array of elements configuration for "common" form
	 * @returns {Array}
	 */
	GetCommonConfig() {
		const _ = this._;
		const marginX = this.MarginX;
		const { minItemWidth, minItemHeight } = this.app.config;
		return [
			{
				rows: [
					{
						label: _("Default block size"),
						view: "label",
						css: "webix_de_sublabel",
					},
					{
						margin: marginX,
						cols: [
							{
								view: "diagram-text",
								inputLabel: "W",
								min: minItemWidth,
								name: "width",
							},
							{
								view: "diagram-text",
								inputLabel: "H",
								min: minItemHeight,
								name: "height",
							},
						],
					},
				],
			},
			this.GetLinkArrowConfig(true),
			this.GetLinkModeConfig(true),
			{},
		];
	}
	/**
	 * Gets an array of elements configuration for "block" form
	 * @returns {Array}
	 */
	GetBlockConfig() {
		const _ = this._;
		const marginX = this.MarginX;
		const { minItemWidth, minItemHeight } = this.app.config;
		const skinConfig = webix.skin.$active;
		return [
			{
				rows: [
					{
						label: _("Block settings"),
						view: "label",
						css: "webix_de_sublabel",
					},
					{
						margin: marginX,
						cols: [
							{
								view: "diagram-text",
								inputLabel: "W",
								min: minItemWidth,
								name: "width",
							},
							{
								view: "diagram-text",
								inputLabel: "H",
								min: minItemHeight,
								name: "height",
							},
						],
					},
					{
						margin: marginX,
						cols: [
							{ view: "diagram-text", inputLabel: "X", name: "x" },
							{ view: "diagram-text", inputLabel: "Y", name: "y" },
						],
					},
					{
						margin: marginX,
						cols: [
							{
								view: "diagram-text",
								inputLabel: "<span class='webix_de_degree'>&deg;</span>",
								name: "angle",
								format: this.AngleFormat(),
							},
							{},
						],
					},
				],
			},
			{
				rows: [
					{
						label: _("Content"),
						view: "label",
						css: "webix_de_sublabel",
					},
					{
						view: "textarea",
						name: "value",
						height: skinConfig.inputHeight * 1.5,
					},
				],
			},
			{
				batch: "shape",
				rows: [
					{
						label: _("Fill"),
						view: "label",
						css: "webix_de_sublabel",
					},
					{
						margin: marginX,
						cols: [
							{
								view: "colorpicker",
								name: "backgroundColor",
								suggest: {
									padding: 0, //for Flat skins
									type: "colorselect",
									body: {
										button: true,
									},
								},
							},
							{
								view: "diagram-text",
								width: 88,
								inputLabel: "%",
								name: "fillOpacity",
								step: 1,
								min: 0,
								max: 100,
							},
						],
					},
				],
			},
			{
				batch: "shape",
				rows: [
					{
						label: _("Border style"),
						view: "label",
						css: "webix_de_sublabel",
					},
					this.GetLineConfig(),
				],
			},
			{
				rows: [
					{
						label: _("Font"),
						view: "label",
						css: "webix_de_sublabel",
					},
					{
						margin: skinConfig.layoutMargin.form,
						rows: [
							{
								margin: marginX,
								cols: [
									{
										view: "colorpicker",
										name: "fontColor",
										suggest: {
											padding: 0, //for Flat skins
											type: "colorselect",
											body: {
												button: true,
											},
										},
									},
									{
										view: "diagram-toggle",
										name: "fontWeight",
										label: "<span class='webix_icon dgi-format-bold'></span>",
										onValue: "bold",
										offValue: "normal",
										width: skinConfig.inputHeight - 2,
									},
									{
										view: "diagram-toggle",
										name: "fontStyle",
										label: "<span class='webix_icon dgi-format-italic'></span>",
										onValue: "italic",
										offValue: "normal",
										width: skinConfig.inputHeight - 2,
									},
								],
							},
							{
								margin: marginX,
								cols: [
									{
										view: "combo",
										name: "fontFamily",
										css: "webix_diagram_editor_select",
										options: this.GetFontFamilyOptions(),
									},
									{
										view: "combo",
										name: "fontSize",
										width: 80,
										css: "webix_diagram_editor_select",
										options: this.GetFontSizeOptions(),
									},
								],
							},
							{
								margin: skinConfig.layoutMargin.form,
								cols: [
									{
										view: "segmented",
										name: "textAlign",
										optionWidth:
											skinConfig.inputHeight - skinConfig.inputPadding * 2,
										value: "edges",
										css: "webix_de_segmented",
										options: [
											{
												id: "left",
												value: this.IconTemplate("dgi-format-align-left"),
											},
											{
												id: "center",
												value: this.IconTemplate("dgi-format-align-center"),
											},
											{
												id: "right",
												value: this.IconTemplate("dgi-format-align-right"),
											},
										],
									},

									{
										view: "segmented",
										name: "textVAlign",
										optionWidth:
											skinConfig.inputHeight - skinConfig.inputPadding * 2,
										value: "edges",
										css: "webix_de_segmented webix_diagram_right_align",
										options: [
											{
												id: "top",
												value: this.IconTemplate(
													"dgi-format-vertical-align-top"
												),
											},
											{
												id: "center",
												value: this.IconTemplate(
													"dgi-format-vertical-align-center"
												),
											},
											{
												id: "bottom",
												value: this.IconTemplate(
													"dgi-format-vertical-align-bottom"
												),
											},
										],
									},
								],
							},
						],
					},
				],
			},
			{
				batch: "text",
				rows: [
					{
						label: _("Background"),
						view: "label",
						css: "webix_de_sublabel",
					},
					{
						view: "checkbox",
						name: "transparent",
						labelWidth: 0,
						labelRight: _("Transparent"),
					},
				],
			},
			{},
		];
	}
	/**
	 * Gets an array of elements configuration for "link" form
	 * @returns {Array}
	 */
	GetLinkConfig() {
		const _ = this._;
		return [
			this.GetLinkArrowConfig(),
			this.GetLinkModeConfig(),
			{
				rows: [
					{
						label: _("Line style"),
						view: "label",
						css: "webix_de_sublabel",
					},
					this.GetLineConfig(),
				],
			},
			{},
		];
	}
	/**
	 * Gets configuration for "Link mode" row
	 * @param isCommonForm {Boolean} defines whether config is applied to common form
	 * @returns {Object}
	 */
	GetLinkModeConfig(isCommonForm) {
		const _ = this._;
		const skinConfig = webix.skin.$active;
		return {
			rows: [
				{
					label: _(isCommonForm ? "Default link mode" : "Link mode"),
					view: "label",
					css: "webix_de_sublabel",
				},
				{
					cols: [
						{
							view: "segmented",
							name: "mode",
							optionWidth: skinConfig.inputHeight - skinConfig.inputPadding * 2,
							value: "edges",
							css: "webix_de_segmented",
							options: [
								{
									id: "edges",
									value: this.IconTemplate("dgi-subdirectory-arrow-right"),
								},
								{
									id: "direct",
									value: this.IconTemplate("dgi-arrow-bottom-right"),
								},
							],
						},
						{},
					],
				},
			],
		};
	}
	/**
	 * Gets configuration for "Arrow" row
	 * @param isCommonForm {Boolean} defines whether config is applied to common form
	 * @returns {Object}
	 */
	GetLinkArrowConfig(isCommonForm) {
		const _ = this._;
		const marginX = this.MarginX;
		const skinConfig = webix.skin.$active;
		return {
			rows: [
				{
					label: _(isCommonForm ? "Default link arrow" : "Link arrow"),
					view: "label",
					css: "webix_de_sublabel",
				},
				{
					margin: marginX,
					cols: [
						{
							view: "richselect",
							name: "arrow",
							optionWidth: skinConfig.inputHeight - skinConfig.inputPadding * 2,
							placeholder: this.NoneTemplate(),
							css: "webix_diagram_editor_select",
							options: this.GetArrowOptions(),
						},
						{
							view: "button",
							batch: "link",
							width: skinConfig.inputHeight,
							label: "<span class='webix_icon dgi-swap-vertical'></span>",
							css: "webix_de_icon_button",
							click: () => this.app.callEvent("form:swap", []),
						},
						{
							view: "diagram-text",
							icon: false,
							name: "arrowSize",
							width: 60,
							value: 6,
						},
					],
				},
			],
		};
	}
	/**
	 * Gets configuration for a row with "Line" settings (without header row)
	 * @returns {Object}
	 */
	GetLineConfig() {
		const marginX = this.MarginX;
		const skinConfig = webix.skin.$active;
		return {
			margin: marginX,
			cols: [
				{
					view: "colorpicker",
					name: "lineColor",
					css: "webix_de_colorpicker",
					width: skinConfig.inputHeight,
					suggest: {
						padding: 0, //for Flat skins
						type: "colorselect",
						body: {
							button: true,
						},
					},
				},

				{
					view: "richselect",
					name: "lineStyle",
					css: "webix_diagram_editor_select",
					options: [
						{ id: "solid", value: this.SelectOptionTemplate("solid") },
						{
							id: "dashed",
							value: this.SelectOptionTemplate("dashed"),
						},
						{
							id: "dotted",
							value: this.SelectOptionTemplate("dotted"),
						},
					],
				},

				{
					view: "diagram-text",
					icon: false,
					name: "lineWidth",
					width: 60,
					value: 6,
				},
			],
		};
	}

	/**
	 * Get an array of font size options
	 * @returns {Array} {id: number}[]
	 */
	GetFontSizeOptions() {
		return [11, 12, 13, 14, 15, 16, 17, 18, 20, 21].map(a => ({ id: a }));
	}
	/**
	 * Get an array of arrow options
	 * @returns {Array}
	 */
	GetArrowOptions() {
		return this.Styles.getArrows().map(a => {
			if (a)
				return {
					id: a,
					value: this.SelectOptionTemplate(a),
				};
			else
				return {
					$empty: true,
					value: this.NoneTemplate(),
				};
		});
	}
	/**
	 * Get an array of font-family options
	 * @returns {Array}
	 */
	GetFontFamilyOptions() {
		return this.Styles.getFontFamilies().map(f => ({
			id: f,
			value: "<span style='font-family: " + f + "'>" + f + "</span>",
		}));
	}

	/**
	 * An icon template
	 * @param icon {String} icon name
	 * @returns {string} html string
	 */
	IconTemplate(icon) {
		return "<span class='webix_icon " + icon + "'></span>";
	}
	/**
	 * "None" template for select option
	 * @returns {string} html string
	 */
	NoneTemplate() {
		return (
			"<span class='webix_diagram_option_none'>" + this._("None") + "</span>"
		);
	}

	/**
	 * An option template for selects with icons
	 * @param value {String} icon name
	 * @returns {String}
	 */
	SelectOptionTemplate(value) {
		return icons[value];
	}

	/**
	 * Format handlers for angle input
	 * @returns {Object}
	 */
	AngleFormat() {
		return {
			edit: v => (v % 360) + "",
			parse: v => (v % 360) + "",
		};
	}
	/**
	 * Shows/hides views depending on "batch" property in view config
	 * @param view {object} layout view
	 * @param batch {string} batch name to show ( a view with a batch property that is not equal to this param will be hidden)
	 */
	ShowBatch(view, batch) {
		if (view.getChildViews) {
			const views = view.getChildViews();
			views.forEach(sub => {
				if (sub.config.batch) {
					if (batch == sub.config.batch) sub.show();
					else sub.hide();
				}
				this.ShowBatch(sub, batch);
			});
		}
	}

	/**
	 * Update property handler
	 * @param name {string} a name of updated property
	 */
	UpdateValue(name) {
		const values = this.Form.getValues();
		let target = null;
		let newValue = values[name];
		let obj = null;
		if (this.Mode == "common")
			target = name == "width" || name == "height" ? "type" : "linkType";

		if (
			name == "arrow" ||
			(name == "lineColor" &&
				this.Mode == "link" &&
				values.arrow.includes("-fill"))
		)
			obj = this.ApplyArrow(name, newValue, target);
		else if (name == "fontWeight" || name == "fontFamily")
			obj = this.ApplyFontWeight(name, newValue);
		else if (values.square && (name == "width" || name == "height"))
			obj = this.ApplySquare(name, newValue);
		else if (name == "fillOpacity") newValue = newValue / 100;
		else if (name == "transparent") obj = { fillOpacity: newValue ? 0 : 1 };
		obj = obj || { [name]: newValue };

		this.app.callEvent("form:update", [obj, target]);
	}

	/**
	 * Applies 500 or "bold" as "font-weight" depending on "font-family"
	 * @param name {string} a style property name ( "fontWeight" or "fontFamily")
	 * @param value {string} a style property value
	 * @returns {number|string} a style property value ( for "fontWeight" property value can be 500 or "bold" )
	 */
	ApplyFontWeight(name, value) {
		const elements = this.Form.elements;
		let obj = { [name]: value };
		if (
			name == "fontWeight" &&
			value == "bold" &&
			elements.fontFamily.getValue() == "Roboto"
		)
			obj.fontWeight = 500;
		// set 500 as "bold" if font-family is Roboto
		else if (name == "fontFamily") {
			// set 500 or "bold" depending on font-family
			let fWeight = elements.fontWeight.getValue();
			if (fWeight == "500" || fWeight == "bold")
				obj.fontWeight = value == "Roboto" ? 500 : "bold";
		}
		return obj;
	}

	/**
	 * Applies an arrow background depending on selected arrow type
	 * @param name {string} a property name: "arrow" or "lineColor"
	 * @param value {string} a property value
	 * @param target {null|string} a target name (null or "linkType")
	 * @returns {string} an arrow property value for the diagram
	 */
	ApplyArrow(name, value, target) {
		let obj;
		if (name == "arrow") {
			// set backgroundColor for arrows with "-fill" in a type name
			let bg = null;
			if (value.includes("-fill")) {
				value = value.replace(/-fill/, "");
				bg = target
					? this.Styles.getDefaults().line.lineColor
					: this.Form.elements.lineColor.getValue();
			} else if (value == "none") value = false;
			obj = { arrow: value, backgroundColor: bg };
		} else obj = { lineColor: value, backgroundColor: value };
		return obj;
	}

	/**
	 * Applies "square" shape property: sets the same height as width and vice versa
	 * @param name {string} a property name: "width" or "height"
	 * @param value {string} a property value
	 */
	ApplySquare(name, value) {
		const dim = name == "width" ? "height" : "width";
		let obj = {};
		obj[name] = obj[dim] = value;
		this.Form.elements[dim].setValue(value);
		return obj;
	}
}
