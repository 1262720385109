import DragScroll from "./dragscroll";
import { drawLine, getPoint } from "./painter";
import { capitalize } from "./styles";

export function initDnD(master, view) {
	const ctrl = webix.copy(DragControl);

	ctrl.view = view;
	ctrl.master = master;

	ctrl.State = master.app.getState();
	ctrl.Local = master.Local;

	webix.DragControl.addDrag(view.$view, ctrl);
	webix.DragControl.addDrop(view.$view, ctrl, true);
}

function getInnerOffset(view, offset) {
	const c = view.config;
	return {
		x: offset.x + (c.paddingX || c.padding),
		y: offset.y + (c.paddingY || c.padding),
	};
}

const DragControl = {
	$longTouchLimit: true,
	$dragCreate: function(t, e) {
		const id = this.locateEvent(e);
		const ctx = this.getContext();

		let type;
		if (id) {
			// close context menu
			this.master.HideContext();

			if ((type = e.target.getAttribute("webix_resizer"))) {
				ctx.submode = { start: type };
				ctx.mode = "resize";
				type.split(",").forEach(a => (ctx.submode[a] = true));
			} else if ((type = e.target.getAttribute("webix_connector"))) {
				ctx.mode = "links";
				ctx.submode = type;
			} else if (e.target.classList.contains("webix_de_rotate")) {
				ctx.mode = "rotate";
			} else {
				ctx.mode = "move";
			}
			return Modes[ctx.mode].$dragCreate.call(this, t, e, id);
		} else if ((type = e.target.getAttribute("webix_link_path"))) {
			// close context menu
			this.master.HideContext();

			ctx.mode = "path";
			ctx.submode = type.split(",");
			return Modes[ctx.mode].$dragCreate.call(this, t, e);
		}
		return false;
	},
	$dragDestroy: function(t, node, e) {
		const ctx = this.getContext();
		if (ctx.from === this) ctx.from = this.view;

		if (Modes[ctx.mode].dragScroll) DragScroll.reset(ctx, true);

		if (Modes[ctx.mode].$dragDestroy)
			Modes[ctx.mode].$dragDestroy.call(this, t, node, e);

		if (!ctx.$render) this.view.render();

		webix.html.remove(node);
		return false;
	},
	$dragIn: function(s, t, e) {
		const ctx = this.getContext();
		if (!Modes[ctx.mode]) return false;

		if (Modes[ctx.mode].dragScroll) {
			DragScroll.reset(ctx);
			DragScroll.start(ctx, e);
		}

		if (Modes[ctx.mode].$dragIn)
			return Modes[ctx.mode].$dragIn.call(this, s, t, e);
		return true;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		return Modes[ctx.mode].$dragPos.call(this, pos);
	},
	$dragOut: function(s, t, n, e) {
		const ctx = this.getContext();
		if (Modes[ctx.mode].$dragOut)
			return Modes[ctx.mode].$dragOut.call(this, s, t, n, e);
		return null;
	},
	$drop: function(s, t, e) {
		const ctx = this.getContext();
		if (!Modes[ctx.mode]) return false;

		if (Modes[ctx.mode].$drop) return Modes[ctx.mode].$drop.call(this, s, t, e);
	},
	locateEvent: function(ev, context) {
		if (webix.env.touch && context) {
			ev = document.elementFromPoint(context.x, context.y);
			context.target = ev;
		}
		return webix.html.locate(ev, "webix_dg_id");
	},
	getEventContext: function(e) {
		if (webix.env.touch) {
			if (e.changedTouches && !(e.touches && e.touches[0])) {
				const t = e.changedTouches[0];
				return { x: t.pageX, y: t.pageY };
			}
			return e.time ? e : webix.env.mouse.context(e);
		}
		return { x: e.clientX, y: e.clientY };
	},
	getContext: function() {
		return webix.DragControl.getContext();
	},
	getNode: function() {
		return webix.DragControl.getNode();
	},
};

const add = {
	$drop: function(s, t, e) {
		const ctx = this.getContext();
		const node = this.getNode();
		const item = ctx.from.getItem(ctx.start);
		const scroll = this.view.getScrollState();
		const step = this.State.gridStep;
		const offset = getInnerOffset(this.view, webix.html.offset(t));
		const _ = this.master.app.getService("locale")._;

		if (!this.master.app.callEvent("diagram:beforedrop", [ctx, e]))
			return false;

		const zoom = this.view.config.zoom;
		const nitem = {
			x:
				Math.round(
					(parseInt(node.style.left) - offset.x + scroll.x) / step / zoom
				) * step,
			y:
				Math.round(
					(parseInt(node.style.top) - offset.y + scroll.y) / step / zoom
				) * step,
		};
		if (ctx.start != "default") nitem.type = ctx.start;

		let shape = this.view.getShape(nitem.type) || {};
		if (!item.template || shape.template == "text")
			nitem.value = _(capitalize(shape.name));

		if (shape.square && !shape.width) {
			nitem.width = shape.height || this.view.type.height;
			nitem.x +=
				Math.round((this.view.type.width - nitem.width) / 2 / zoom / step) *
				step;
		}

		ctx.$render = true;
		const nid = this.Local.addBlock(nitem);
		this.view.select(nid);
	},
};

const move = {
	dragScroll: {
		direction: "xy",
		senseX: 100,
		senseY: 100,
	},
	$dragCreate: function(t, e, id) {
		const ctx = this.getContext();
		const obj = this.view.getItem(id);
		const node = this.view.getItemNode(id);
		const scroll = this.view.getScrollState();
		const evContext = this.getEventContext(e);

		webix.extend(
			ctx,
			{
				id,
				from: this.view,
				snode: t.querySelector(".webix_scroll_cont"),
				node,
				scroll,
				zoom: this.view.config.zoom,
				l: obj.x,
				t: obj.y,
				x: evContext.x,
				y: evContext.y,
			},
			true
		);

		if (Modes[ctx.mode].dragScroll)
			webix.extend(ctx, Modes[ctx.mode].dragScroll);

		if (!this.master.app.callEvent("diagram:beforedrag", [ctx, e]))
			return false;

		// hide link path
		this.master.ShowLinkPath();

		const html = node.cloneNode(true);
		html.className += " webix_drag_zone";

		webix.html.addCss(t, "webix_de_mode_" + ctx.mode, true);
		node.style.visibility = "hidden";
		t.style.cursor = "move";

		t.querySelector(".webix_diagram_shapes").appendChild(html);
		return html;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const { l, t, x, y, scroll, zoom, id } = ctx;
		const nscroll = this.view.getScrollState();
		const step = this.State.gridStep;

		pos.x =
			Math.round((l + (pos.x - x - scroll.x + nscroll.x) / zoom) / step) * step;
		pos.y =
			Math.round((t + (pos.y - y - scroll.y + nscroll.y) / zoom) / step) * step;

		const item = {
			id,
			x: pos.x,
			y: pos.y,
		};
		this.master.Action({ name: "sync-links", id, item });
	},
	$drop: function(s, t, e) {
		const ctx = this.getContext();
		const node = this.getNode();

		if (!this.master.app.callEvent("diagram:beforedrop", [ctx, e]))
			return false;

		const x = parseInt(node.style.left);
		const y = parseInt(node.style.top);

		ctx.$render = true;
		this.Local.updateBlock(ctx.id, { x, y });
		this.view.select(ctx.id);
	},
	$dragDestroy: function(t) {
		const ctx = this.getContext();
		webix.html.removeCss(t, "webix_de_mode_" + ctx.mode);
		ctx.node.style.visibility = "";
		t.style.cursor = "";
	},
};

const links = {
	dragScroll: {
		direction: "xy",
		senseX: 100,
		senseY: 100,
	},
	$dragCreate: function(t, e, id) {
		const ctx = this.getContext();
		const obj = this.view.getItem(id);
		const node = this.view.getItemNode(id);
		const offset = getInnerOffset(this.view, webix.html.offset(t));

		webix.extend(
			ctx,
			{
				id,
				from: this.view,
				node,
				startNode: e.target,
				offset,
				zoom: this.view.config.zoom,
				start: getPoint(this.view, obj, ctx.submode, 4),
			},
			true
		);

		if (Modes[ctx.mode].dragScroll)
			webix.extend(ctx, Modes[ctx.mode].dragScroll);

		if (!this.master.app.callEvent("diagram:beforedrag", [ctx, e]))
			return false;

		webix.html.addCss(ctx.startNode, "webix_de_selected_dot", true);

		const html = webix.html.create("div", { visibility: "hidden" });
		document.body.appendChild(html);
		return html;
	},
	$dragIn: function(s, t, e) {
		const ctx = this.getContext();
		const evContext = this.getEventContext(e);
		const id = this.locateEvent(e, evContext);

		if (!id) return false;

		const node = this.view.getItemNode(id);
		if (id != ctx.target) {
			webix.html.addCss(node, "webix_de_connect_end", true);
			ctx.target = id;
		}
		return node;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const scroll = this.view.getScrollState();
		const { offset, zoom } = ctx;

		ctx.end = [
			(pos.x - offset.x + scroll.x) / zoom,
			(pos.y - offset.y + scroll.y) / zoom,
		];
		this.master.Action({ name: "temp-link", start: ctx.start, end: ctx.end });
	},
	$dragOut: function(s, t, n, e) {
		const ctx = this.getContext();
		const evContext = this.getEventContext(e);
		const id = this.locateEvent(e, evContext);

		if (ctx.target && id != ctx.target) {
			const node = this.view.getItemNode(ctx.target);
			webix.html.removeCss(node, "webix_de_connect_end");
			ctx.target = null;
		}
		return null;
	},
	$drop: function(s, t, e) {
		const ctx = this.getContext();
		const evContext = this.getEventContext(e);

		const source = ctx.id;
		const target = this.locateEvent(e, evContext);
		if (!target || source == target) return false;

		ctx.targetId = target;
		if (!this.master.app.callEvent("diagram:beforedrop", [ctx, e]))
			return false;

		const tnode = webix.env.touch ? evContext.target : e.target;
		const from = ctx.submode;
		const to = tnode.getAttribute("webix_connector") || "center";

		const item = { source, target };
		if (from != "center" || to != "center") {
			item.to = to;
			item.from = from;
		} else {
			item.mode = "direct";
		}

		ctx.$render = true;
		const nid = this.Local.addLink(item);
		this.master.SelectLink(nid);
	},
	$dragDestroy: function() {
		const ctx = this.getContext();
		webix.html.removeCss(ctx.startNode, "webix_de_selected_dot");
		this.master.Action({ name: "temp-link" });
	},
};

function correctSubMode(ctx) {
	const points = ["bottom,right", "bottom,left", "top,left", "top,right"];
	const index = points.indexOf(ctx.submode.start);

	const tan = index % 2 ? ctx.w / ctx.h : ctx.h / ctx.w;
	const angle = index * 90 + (Math.atan(tan) * 180) / Math.PI;
	const nindex = Math.floor((angle + ctx.angle) / 90) % 4;

	if (index !== nindex) {
		ctx.submode = {
			start: ctx.submode.start,
			invert: Math.abs(nindex - index) % 2,
			direction: nindex % 2 ? -1 : 1,
		};
		points[nindex].split(",").forEach(a => (ctx.submode[a] = true));
	}
}

const resize = {
	$dragCreate: function(t, e, id) {
		const ctx = this.getContext();
		const obj = this.view.getItem(id);
		const node = this.view.getItemNode(id);
		const scroll = this.view.getScrollState();
		const evContext = this.getEventContext(e);
		webix.extend(
			ctx,
			{
				id,
				from: this.view,
				node,
				startNode: e.target,
				selectNode: node.querySelector(".webix_de_selection_layer"),
				scroll,
				zoom: this.view.config.zoom,
				l: obj.x,
				t: obj.y,
				angle: this.view.getItemValue(id, "angle"),
				w: parseInt(node.style.width),
				h: parseInt(node.style.height),
				x: evContext.x,
				y: evContext.y,
				square: this.view.getItemValue(id, "square"),
			},
			true
		);
		if (ctx.angle) correctSubMode(ctx);

		if (!this.master.app.callEvent("diagram:beforedrag", [ctx, e]))
			return false;

		webix.html.addCss(ctx.startNode, "webix_de_selected_dot", true);
		webix.html.addCss(t, "webix_de_mode_" + ctx.mode, true);

		const s = ctx.submode;
		t.style.cursor =
			(s.top && s.left) || (s.bottom && s.right)
				? "nwse-resize"
				: "nesw-resize";

		const html = webix.html.create("div", { visibility: "hidden" });
		document.body.appendChild(html);
		return html;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const { x, y, scroll, zoom, node, selectNode, submode, id } = ctx;
		const nscroll = this.view.getScrollState();
		const step = this.State.gridStep;

		let dx =
			Math.round((pos.x - x - scroll.x + nscroll.x) / zoom / step) * step;
		let dy =
			Math.round((pos.y - y - scroll.y + nscroll.y) / zoom / step) * step;

		if (submode.invert)
			[dx, dy] = [submode.direction * dy, submode.direction * dx];

		if (ctx.square) {
			const l = ctx.submode.left ? -1 : 1;
			const t = ctx.submode.top ? -1 : 1;
			if (dx * l > dy * t) dy = t * l * dx;
			else dx = t * l * dy;
		}

		if (dx != ctx.dx || dy != ctx.dy) {
			const { l, t, w, h } = ctx;

			ctx.dx = dx;
			ctx.dy = dy;

			const { minItemWidth, minItemHeight } = this.master.app.config;
			if (submode.left) {
				node.style.left = Math.min(l + dx, l + w - minItemWidth) + "px";
				node.style.width = Math.max(w - dx, minItemWidth) + "px";
			} else {
				node.style.width = Math.max(w + dx, minItemWidth) + "px";
			}
			selectNode.style.width = `${parseInt(node.style.width) + 10}px`;

			if (submode.top) {
				node.style.top = Math.min(t + dy, t + h - minItemHeight) + "px";
				node.style.height = Math.max(h - dy, minItemHeight) + "px";
			} else {
				node.style.height = Math.max(h + dy, minItemHeight) + "px";
			}

			selectNode.style.height = `${parseInt(node.style.height) + 10}px`;

			const item = {
				id,
				x: parseInt(node.style.left),
				y: parseInt(node.style.top),
				width: parseInt(node.style.width),
				height: parseInt(node.style.height),
			};
			this.master.Action({ name: "sync-links", id, item });
			this.master.Action({ name: "resize-content", id, item });
		}
	},
	$drop: function(s, t, e) {
		const ctx = this.getContext();
		const node = ctx.node;

		if (!this.master.app.callEvent("diagram:beforedrop", [ctx, e]))
			return false;

		const update = {
			x: parseInt(node.style.left),
			y: parseInt(node.style.top),
			width: parseInt(node.style.width),
			height: parseInt(node.style.height),
		};

		if (update.x == ctx.l) delete update.x;
		if (update.y == ctx.t) delete update.y;
		if (update.width == ctx.w) delete update.width;
		if (update.height == ctx.h) delete update.height;

		if (Object.keys(update).length) {
			ctx.$render = true;
			this.Local.updateBlock(ctx.id, update);
		}
	},
	$dragDestroy: function(t) {
		const ctx = this.getContext();
		webix.html.removeCss(ctx.startNode, "webix_de_selected_dot");
		webix.html.removeCss(t, "webix_de_mode_" + ctx.mode);
		t.style.cursor = "";
	},
};

const rotate = {
	$dragCreate: function(t, e, id) {
		const ctx = this.getContext();
		const obj = this.view.getItem(id);
		const node = this.view.getItemNode(id);
		const offset = getInnerOffset(this.view, webix.html.offset(t));

		webix.extend(
			ctx,
			{
				id,
				from: this.view,
				node,
				offset,
				startNode: e.target,
				zoom: this.view.config.zoom,
				l: obj.x,
				t: obj.y,
				w: parseInt(node.style.width),
				h: parseInt(node.style.height),
			},
			true
		);

		if (!this.master.app.callEvent("diagram:beforedrag", [ctx, e]))
			return false;

		webix.html.addCss(ctx.startNode, "webix_de_angle_text", true);
		webix.html.addCss(t, "webix_de_mode_" + ctx.mode, true);

		t.style.cursor = "move";
		node.style.transformOrigin = "50% 50%";

		const html = webix.html.create("div", { visibility: "hidden" });
		document.body.appendChild(html);
		return html;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const { l, t, w, h, offset, zoom, id } = ctx;
		const scroll = this.view.getScrollState();

		const cx = l + w / 2;
		const cy = t + h / 2;

		const ax = (pos.x - offset.x + scroll.x) / zoom;
		const ay = (pos.y - offset.y + scroll.y) / zoom;

		let angle = Math.round(90 - (Math.atan2(ax - cx, ay - cy) * 180) / Math.PI);
		if (angle < 0) angle += 360;

		if (angle !== ctx.angle) {
			ctx.angle = angle;
			ctx.node.style.transform = `rotate(${angle}deg)`;
			ctx.startNode.style.transform = `rotate(-${angle}deg)`;
			ctx.startNode.innerHTML = angle + "&deg;";

			const item = {
				id,
				angle,
			};
			this.master.Action({ name: "sync-links", id, item });
		}
	},
	$drop: function(s, t, e) {
		const ctx = this.getContext();

		if (!this.master.app.callEvent("diagram:beforedrop", [ctx, e]))
			return false;

		const obj = this.view.getItem(ctx.id);
		if (obj.angle !== ctx.angle) {
			ctx.$render = true;
			this.Local.updateBlock(ctx.id, { angle: ctx.angle });
		}
	},
	$dragDestroy: function(t) {
		const ctx = this.getContext();
		webix.html.removeCss(ctx.startNode, "webix_de_angle_text");
		webix.html.removeCss(t, "webix_de_mode_" + ctx.mode);
		t.style.cursor = "";
	},
};

function getActualPath(path, dots, dx, dy, final) {
	let result = webix.copy(path);
	// add difference
	for (let i = 0; i < dots.length; i++) {
		result[dots[i]][0] += dx;
		result[dots[i]][1] += dy;
	}

	if (final) {
		const temp = [result[0]];
		for (let i = 1; i < result.length - 1; i++) {
			const p = temp[temp.length - 1];
			const c = result[i];
			const n = result[i + 1];
			// remove duplicate points
			if (p[0] == c[0] && c[0] == n[0]) continue;
			else if (p[1] == c[1] && c[1] == n[1]) continue;
			else temp.push(result[i]);
		}
		temp.push(result[result.length - 1]);
		result = temp;
	}
	return result;
}

function convertToArray(points) {
	const result = [];
	for (let i = 0; i < points.length; i++)
		result.push([points[i].x, points[i].y]);
	return result;
}

const path = {
	$dragCreate: function(t, e) {
		const ctx = this.getContext();
		const id = this.State.selected.id;
		const node = this.view.getLinkItemNode(id, "link");
		const scroll = this.view.getScrollState();
		const evContext = this.getEventContext(e);

		webix.extend(
			ctx,
			{
				id,
				from: this.view,
				path: convertToArray(node.points),
				scroll,
				zoom: this.view.config.zoom,
				first: e.target.previousSibling == this.master.TempLink.firstChild,
				last: !e.target.nextSibling,
				vertical: e.target.classList.contains("webix_de_link_line-v"),
				horizontal: e.target.classList.contains("webix_de_link_line-h"),
				x: evContext.x,
				y: evContext.y,
			},
			true
		);

		// add extra points
		if (ctx.submode.length > 1) {
			if (ctx.submode[1] == ctx.path.length - 1)
				ctx.path.push(ctx.path[ctx.path.length - 1]);
			if (ctx.submode[0] == 0) {
				ctx.path.unshift(ctx.path[0]);
				ctx.submode = ctx.submode.map(a => ++a);
			}
		}

		if (!this.master.app.callEvent("diagram:beforedrag", [ctx, e]))
			return false;

		ctx.extraCss = ctx.first || ctx.last ? " webix_de_mode_connect" : "";
		webix.html.addCss(t, "webix_de_mode_" + ctx.mode + ctx.extraCss, true);

		if (ctx.first || ctx.last) t.style.cursor = "pointer";
		else if (ctx.vertical) t.style.cursor = "ew-resize";
		else if (ctx.horizontal) t.style.cursor = "ns-resize";
		else t.style.cursor = "move";

		const html = webix.html.create("div", { visibility: "hidden" });
		document.body.appendChild(html);
		return html;
	},
	$dragIn: function(s, t, e) {
		const ctx = this.getContext();
		if (!ctx.first && !ctx.last) return false;

		const evContext = this.getEventContext(e);
		const id = this.locateEvent(e, evContext);
		if (!id) return false;

		const node = this.view.getItemNode(id);
		if (id != ctx.target) {
			webix.html.addCss(node, "webix_de_connect_end", true);
			ctx.target = id;
		}
		return node;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const { x, y, scroll, zoom, id } = ctx;
		const nscroll = this.view.getScrollState();
		const step = ctx.first || ctx.last ? 1 : this.State.gridStep;

		let dx =
			Math.round((pos.x - x - scroll.x + nscroll.x) / zoom / step) * step;
		let dy =
			Math.round((pos.y - y - scroll.y + nscroll.y) / zoom / step) * step;

		if (ctx.vertical) dy = 0;
		else if (ctx.horizontal) dx = 0;

		if (dx != ctx.dx || dy != ctx.dy) {
			ctx.dx = dx;
			ctx.dy = dy;

			const path = getActualPath(ctx.path, ctx.submode, dx, dy);
			this.master.Action({ name: "sync-path", id, path });
		}
	},
	$dragOut: function(s, t, n, e) {
		const ctx = this.getContext();
		if (!ctx.first && !ctx.last) return false;

		const evContext = this.getEventContext(e);
		const id = this.locateEvent(e, evContext);

		if (ctx.target && id != ctx.target) {
			const node = this.view.getItemNode(ctx.target);
			webix.html.removeCss(node, "webix_de_connect_end");
			ctx.target = null;
		}
		return null;
	},
	$drop: function(s, t, e) {
		const ctx = this.getContext();
		const evContext = this.getEventContext(e);
		const link = this.view.getLinks().getItem(ctx.id);

		if (ctx.first || ctx.last) {
			const tnode = webix.env.touch ? evContext.target : e.target;
			const target = this.locateEvent(e, evContext);

			let to;
			if (target && (to = tnode.getAttribute("webix_connector"))) {
				ctx.targetId = target;
				ctx.targetSide = to;
			}
		}

		if (!this.master.app.callEvent("diagram:beforedrop", [ctx, e]))
			return false;

		let update = { mode: undefined };
		if (!webix.isArray(link.line)) {
			update.from = link.from || "center";
			update.to = link.to || "center";
		}

		if (ctx.targetId && ctx.targetSide) {
			update[ctx.first ? "from" : "to"] = ctx.targetSide;
			update[ctx.first ? "source" : "target"] = ctx.targetId;

			if (webix.isArray(link.line)) {
				const temp = { ...link, ...update };
				update.line = drawLine(this.view, temp, temp.from, temp.to);
			} else if (update.from == update.to && update.to == "center")
				update.from = update.to = undefined;
		} else if (ctx.dx || ctx.dy) {
			if (ctx.first) update.from = undefined;
			else if (ctx.last) update.to = undefined;
			update.line = getActualPath(ctx.path, ctx.submode, ctx.dx, ctx.dy, true);
		} else update = false;

		if (update) {
			ctx.$render = true;
			this.Local.updateLink(ctx.id, update);
		}
	},
	$dragDestroy: function(t, node, e) {
		const ctx = this.getContext();

		this.$drop(t, null, e); // simulate $drop to save dot position

		webix.html.removeCss(t, "webix_de_mode_" + ctx.mode + ctx.extraCss);
		t.style.cursor = "";
	},
};

const Modes = {
	add,
	move,
	links,
	resize,
	rotate,
	path,
};
