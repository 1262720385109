import { JetView } from "webix-jet";
import { initDnD } from "../helpers/dnd";
import { drawLine } from "../helpers/painter";
import hotkey from "jet-hotkey";

import "../diagramview";

import ContextView from "./windows/context";

export default class WorkSpaceView extends JetView {
	config() {
		this.State = this.app.getState();
		this._ = this.app.getService("locale")._;
		const active = webix.skin.$active;
		const touchCss = webix.env.touch ? " webix_de_touch_diagram" : "";

		const diagram = {
			view: "diagram",
			css: "webix_de_diagram" + touchCss,
			padding: active.inputHeight + 34,
			autoplace: false,
			select: true,
			data: this.app.config.data,
			links: this.app.config.links,
			shapes: this.app.config.shapes,
			scheme: this.app.config.scheme,
			type: {
				template: (o, c) => this.TemplateValue(o, c),
				templateTextEnd: (o, c) => this.TemplateSelection(o, c),
			},
			linkType: {
				shadow: 2,
			},
			on: {
				onAfterSelect: id => {
					this.State.selected = { id };
				},
			},
			onClick: {
				webix_window: () => false,
				webix_c_scroll_x: () => false,
				webix_c_scroll_y: () => false,
				webix_diagram: (e, id) => {
					if (!id) this.State.selected = null;
				},
				webix_diagram_link: e => {
					const id = webix.html.locate(e, "webix_dg_link_id");
					this.SelectLink(id, e);
					return false;
				},
				webix_diagram_arrow: e => {
					const id = webix.html.locate(e, "webix_dg_arrow_id");
					this.SelectLink(id, e);
					return false;
				},
				webix_diagram_link_shadow: e => {
					const id = webix.html.locate(e, "webix_dg_shadow_id");
					this.SelectLink(id, e);
					return false;
				},
			},
		};

		if (this.app.config.type) webix.extend(diagram.type, this.app.config.type);

		return diagram;
	}

	init(view) {
		this.View = view;

		const { minItemWidth, minItemHeight } = this.app.config;
		this.UpdateCommonValue(
			{
				width: Math.max(minItemWidth, view.type.width),
				height: Math.max(minItemHeight, view.type.height),
			},
			"type",
			true
		);
		this.app.getService("styles").connect(view);

		// connect history module after setting types
		this.History = this.app.getService("history");
		this.Local = this.app.getService("local");

		this.Local.connect(view, this.History);
		this.History.connect(this, this.Local);

		this.on(view, "onAfterUnSelect", id => {
			view.removeCss(id, "webix_de_connect_start");
		});
		this.on(view, "onAfterRender", () => this.AfterRender());
		this.on(this.app, "toolbar:autoplace", () => this.Autoplace());

		// initialize context helper
		this.Context = this.ui(ContextView, { container: view.$view });
		this.on(this.app, "context:action", o => this.Action(o));
		// track selection
		this.on(this.State.$changes, "selected", v => this.TrackSelection(v));
		// setting property update handler
		this.on(this.app, "form:update", (obj, target) =>
			this.ApplyChanges(obj, target)
		);
		// setting "swap arrow" handler
		this.on(this.app, "form:swap", () =>
			this.SwapLinkDirection(this.State.selected.id)
		);
		// zoom change handler
		this.on(this.State.$changes, "zoom", (z, oldZoom) => this.Zoom(z, oldZoom));

		// grid handlers
		this.on(this.State.$changes, "gridStep", step => this.DrawGrid(step));
		this.on(this.app, "toolbar:preview", v =>
			this.DrawGrid(this.State.gridStep, v)
		);
		this._scroll_handler = webix.event(this.View.$view, "scroll", () => {
			this.SetGridBgPosition();
		});
		this.InitTempLink();

		// initialize dnd
		initDnD(this, view);

		this.AddHotkeys();
	}

	destroy() {
		this._scroll_handler = webix.eventRemove(this._scroll_handler);
	}

	/**
	 * Restore selection and the position of the context menu after diagram render
	 */
	AfterRender() {
		const sel = this.State.selected;
		if (sel) {
			const obj = this.GetItem(sel.id, sel.link);
			if (!obj) return (this.State.selected = null);

			this.ShowContext(obj, sel.link);
			if (sel.link) this.ShowLinkPath(sel.id);
			this.app.callEvent("diagram:syncform", [obj]);
		} else {
			this.app.callEvent("values:defaults", []);
		}
	}

	/**
	 * Shows a context menu
	 * @param obj {Object} the data object
	 * @param link {Boolean} defines it as a link or block
	 * @param e {MouseEvent} native browser event
	 */
	ShowContext(obj, link, e) {
		if (!obj) {
			const sel = this.State.selected;
			if (sel) {
				link = sel.link;
				obj = this.GetItem(sel.id, link);
			} else return;
		}

		const config = this.GetContextConfig(obj, link, e);
		this.Context.Show(obj, link, config, this.State.zoom);
	}

	/**
	 * Gathers additional information about pressed objects
	 * @param obj {Object} the data object
	 * @param link {Boolean} defines it as a link or block
	 * @param e {MouseEvent} native browser event
	 * @return {Object} config object
	 */
	GetContextConfig(obj, link, e) {
		const c = this.View.config;
		const config = {
			e,
			px: c.paddingX || c.padding || 0,
			py: c.paddingY || c.padding || 0,
		};

		if (link) {
			if (config.e) {
				config.view = this.View.$view;
				config.scroll = this.View.getScrollState();
			} else config.node = this.View.getLinkItemNode(obj.id, "link");
		} else {
			config.value = this.View.data.getMark(obj.id, "webix_de_connect_start");
			config.angle = this.View.getItemValue(obj.id, "angle");
			if (config.angle) config.node = this.View.getItemNode(obj.id);
		}
		return config;
	}

	/**
	 * Hides a context menu
	 */
	HideContext() {
		this.Context.Hide();
	}

	/**
	 * Returns a string with the HTML of the custom selection layout and all of the drag-and-drop triggers
	 * @param obj {Object} the data object
	 * @param common {Object} diagram type object
	 * @return {string}
	 */
	TemplateSelection(obj, common) {
		const shape = this.View.getShape(obj.type || common.type) || {};
		const size = {
			width: obj.width * 1 || shape.width || common.width,
			height: obj.height * 1 || shape.height || common.height,
		};
		const margin = 2 * Math.ceil(5 / Math.max(0.5, this.View.config.zoom));
		const style = `width:${size.width + margin}px;height:${size.height +
			margin}px;`;
		return `</span></div>
		<div class="webix_de_selection_layer" style="${style}">
			<div webix_resizer="top,left" class="webix_de_resize webix_de_top_left"></div>
			<div webix_connector="left" class="webix_de_connect webix_de_left"></div>
			<div webix_resizer="bottom,left" class="webix_de_resize webix_de_bottom_left"></div>
			<div webix_connector="top" class="webix_de_connect webix_de_top"></div>
			<div webix_connector="center" class="webix_de_connect webix_de_center"></div>
			<div webix_connector="bottom" class="webix_de_connect webix_de_bottom"></div>
			<div webix_resizer="top,right" class="webix_de_resize webix_de_top_right"></div>
			<div webix_connector="right" class="webix_de_connect webix_de_right"></div>
			<div webix_resizer="bottom,right" class="webix_de_resize webix_de_bottom_right"></div>
			<div class="webix_de_rotate"></div>
		</div>`;
	}

	/**
	 * Returns the HTML string for the back grid of the diagram
	 * @param step {number} grid step (defines step for resize and positioning)
	 * @param color {string} color of dots in the format "#ffffff"
	 * @return {string}
	 */
	GridTemplate(step, color) {
		color = color || webix.skin.$name == "contrast" ? "#777" : "#c4c4c4";
		const w = "100%",
			h = "100%";
		const r = step < 3 ? 0.2 : 0.5;

		return (
			`<svg xmlns="http://www.w3.org/2000/svg" width="${w}" height="${h}">` +
			`<pattern id="webixDiagramPattern"  width="${step}" height="${step}" patternUnits="userSpaceOnUse">` +
			`<circle cx="0" cy="0" r="${r}" fill="${color}" />` +
			`<circle cx="${step}" cy="0" r="${r}" fill="${color}" />` +
			`<circle cx="0" cy="${step}" r="${r}" fill="${color}" />` +
			`<circle cx="${step}" cy="${step}" r="${r}" fill="${color}" /></pattern>` +
			`<rect x="0" y="0" width="${w}" height="${h}" fill="url(#webixDiagramPattern)" /></svg>`
		);
	}

	/**
	 * Returns a string with the HTML code of the object's value
	 * @param obj {Object} the data object
	 * @param common {Object} diagram type object
	 * @return {string}
	 */
	TemplateValue(obj, common) {
		const config = this.app.config;
		const template =
			config.template || (config.type ? config.type.template : null);
		if (template) {
			return template.call(this, obj, common);
		}
		return obj.value || "";
	}

	/**
	 * Layout initialization for temporary links
	 */
	InitTempLink() {
		const link = (this.TempLink = document.createElementNS(
			"http://www.w3.org/2000/svg",
			"svg"
		));
		link.setAttributeNS(null, "width", "100%");
		link.setAttributeNS(null, "height", "100%");

		link.classList.add("webix_de_temp_line");
		link.innerHTML = "<polyline points='' />";

		const cont = this.View.$view.querySelector(".webix_scroll_cont");
		cont.appendChild(link);
	}

	/**
	 * Global selector handler on diagram. Only one can be selected (block or link)
	 * @param value {Object} the new value of the "selected" field in the state object
	 */
	TrackSelection(value) {
		if (!value) {
			this.View.unselectAll();
			this.UnselectLink();

			this.HideContext();
			this.app.callEvent("diagram:select", []);
		} else {
			const { id, link } = value;
			const obj = { ...this.GetItem(id, link) };

			if (link) this.View.unselectAll();
			else this.UnselectLink();
			this.ShowContext(obj, link, value.e);
			this.app.callEvent("diagram:select", [obj, link]);
		}
	}

	/**
	 * Selects the specified link
	 * @param id {number|string} a link id
	 * @param e {MouseEvent} native browser event
	 */
	SelectLink(id, e) {
		const sid = this.SelectedLink;
		if (id && id != sid) {
			// unselect prev selection
			this.UnselectLink();
			this.SelectedLink = id;

			const nodes = this.View.getLinkItemNode(id);
			if (nodes.link) nodes.link.classList.add("webix_selected");
			if (nodes.arrow) nodes.arrow.classList.add("webix_selected");
			if (nodes.shadow) nodes.shadow.classList.add("webix_selected");

			const links = this.View.getLinks();
			links.data.addMark(id, "webix_selected", true, true, true);

			// onAfterSelect
			this.State.selected = { id, e, link: true };
			this.ShowLinkPath(id, nodes.link.points);
		}
	}

	/**
	 * Removes selection from the specified link
	 */
	UnselectLink() {
		const sid = this.SelectedLink;
		if (sid) {
			const links = this.View.getLinks();
			if (links.exists(sid)) {
				const nodes = this.View.getLinkItemNode(sid);
				if (nodes.link) nodes.link.classList.remove("webix_selected");
				if (nodes.arrow) nodes.arrow.classList.remove("webix_selected");
				if (nodes.shadow) nodes.shadow.classList.remove("webix_selected");

				links.data.removeMark(sid, "webix_selected", true, true);
			}
			this.SelectedLink = null;
			this.ShowLinkPath();
		}
	}

	/**
	 * Render selected link path
	 * @param id {number|string} a link id
	 * @param line {Array} array of points in one of the formats ([[x,y],...] or [{x,y},...])
	 */
	ShowLinkPath(id, line) {
		let html = "<polyline points='' />";
		const links = this.View.getLinks();

		if (id && links.exists(id)) {
			line = line || this.View.getLinkItemNode(id, "link").points;
			if (line[0] && webix.isArray(line[0]))
				line = line.map(a => {
					return { x: a[0], y: a[1] };
				});

			html += this.TemplateLinkDots(0, line[0], "dot", "webix_de_first_dot");
			for (let i = 1; i < line.length; i++) {
				const dot = line[i];
				const prev = line[i - 1];

				let mode, pos, diff;
				if (dot.x == prev.x && (diff = Math.abs(dot.y - prev.y)) >= 30) {
					mode = "line-v";
					pos = { x: dot.x - 4, y: Math.min(dot.y, prev.y) + diff / 2 - 8 };
				} else if (dot.y == prev.y && (diff = Math.abs(dot.x - prev.x)) >= 30) {
					mode = "line-h";
					pos = { x: Math.min(dot.x, prev.x) + diff / 2 - 8, y: dot.y - 4 };
				}

				if (mode)
					html += this.TemplateLinkDots([i - 1, i].join(","), pos, mode);

				const css = i == line.length - 1 ? "webix_de_last_dot" : "";
				html += this.TemplateLinkDots(i, dot, "dot", css);
			}
		}

		this.TempLink.innerHTML = html;
	}

	/**
	 * Returns a string with the HTML code of one point from the link path.
	 * @param index {number} current point index
	 * @param pos {Object} current point position
	 * @param mode {string} defines it as a point or vertical\horizontal line
	 * @param css {string} additional css
	 * @return {string}
	 */
	TemplateLinkDots(index, pos, mode, css) {
		let type, style;
		const diff = this.View.linkType.shadow / 2;
		if (mode == "dot") {
			type = "circle";
			style = `cx="${pos.x + diff}" cy="${pos.y + diff}"`;
		} else {
			type = "rect";
			style = `x="${pos.x + diff}" y="${pos.y + diff}"`;
		}
		return `<${type} ${style} webix_link_path="${index}" class="webix_de_link_${mode} ${css ||
			""}" />`;
	}

	/**
	 * Handles actions with diagram
	 * @param obj {Object} settings of an action
	 */
	Action(obj) {
		if (obj.name == "clone") {
			const { id } = this.State.selected;
			const nid = this.CloneBlock(id);
			this.View.select(nid);
		} else if (obj.name == "remove") {
			if (this.State.selected) {
				const { id, link } = this.State.selected;
				if (link) this.Local.removeLink(id);
				else this.Local.removeBlock(id);
			}
		} else if (obj.name == "mode") {
			const { id } = this.State.selected;
			if (obj.value) this.View.addCss(id, "webix_de_connect_start");
			else this.View.removeCss(id, "webix_de_connect_start");
		} else if (obj.name == "temp-link") {
			const link = this.TempLink.firstChild;

			if (!obj.start) {
				link.setAttribute("points", "");
			} else {
				const points = obj.start.join(",") + " " + obj.end.join(",");
				link.setAttribute("points", points);
			}
		} else if (obj.name == "sync-links") {
			const l = this.View.getLinks();
			l.find(a => a.source == obj.id || a.target == obj.id).forEach(link => {
				const points = drawLine(this.View, link, link.from, link.to, obj.item);
				const nodes = this.View.getLinkItemNode(link.id);
				if (nodes.link) this.SetCustomLine(nodes.link, points);
				// sync only visible
				if (nodes.shadow && nodes.shadow.classList.contains("webix_selected")) {
					const diff = this.View.linkType.shadow / 2;
					this.SetCustomLine(nodes.shadow, points, diff);
				}
				if (nodes.arrow) webix.html.remove(nodes.arrow);
			});
		} else if (obj.name == "sync-path") {
			const nodes = this.View.getLinkItemNode(obj.id);
			if (nodes.link) this.SetCustomLine(nodes.link, obj.path);
			if (nodes.shadow) {
				const diff = this.View.linkType.shadow / 2;
				this.SetCustomLine(nodes.shadow, obj.path, diff);
			}
			if (nodes.arrow) webix.html.remove(nodes.arrow);
			this.ShowLinkPath(obj.id, obj.path);
		} else if (obj.name == "copy") {
			const selected = this.State.selected;
			if (selected && !selected.link) this.CopyId = selected.id;
			else this.CopyId = null;
		} else if (obj.name == "paste") {
			if (this.CopyId && this.GetItem(this.CopyId))
				this.CloneBlock(this.CopyId);
		} else if (obj.name == "undo") {
			this.History.undo();
		} else if (obj.name == "redo") {
			this.History.redo();
		} else if (obj.name == "resize-content") {
			const item = { ...this.View.getItem(obj.id), ...obj.item };
			const shape = this.View.getShape(item.type || this.View.type.type);
			const baseTemplate =
				typeof shape.template == "string"
					? this.Local.getShapeTemplate(shape.template)
					: null;
			if (typeof (baseTemplate || shape).template == "function") {
				const str = this.View.type.templateShape.call(
					this.View,
					item,
					this.View.type
				);
				const node = this.View.getItemNode(obj.id);
				node.removeChild(node.children[0]);
				const d = webix.html.create("div", null, str);
				node.insertBefore(d.children[0], node.children[0]);
			}
		}
	}

	/**
	 * Updates the route of the line at the current node (after diagram render, all changes will be canceled)
	 * @param node {SVG element} line node to update
	 * @param line {Array} array of points
	 * @param diff {number} line offset
	 */
	SetCustomLine(node, line, diff) {
		diff = diff || 0;
		node.setAttribute(
			"points",
			line
				.map(a => {
					a = webix.isArray(a) ? a : a.split(",");
					return [Math.ceil(1 * a[0] + diff), Math.ceil(1 * a[1] + diff)].join(
						","
					);
				})
				.join(" ")
		);
	}

	/**
	 * Clone block
	 * @param id {number|string} a block id
	 * @return {number|string} id of the new block
	 */
	CloneBlock(id) {
		const obj = webix.copy(this.View.getItem(id));

		delete obj.id;
		obj.x = obj.x + this.View.type.width + 20;
		obj.y = obj.y + this.View.type.height + 20;

		return this.Local.addBlock(obj);
	}

	/**
	 * Automatic block placement
	 */
	Autoplace() {
		const history = {
			data: this.Local.data().serialize(),
			links: this.Local.links().serialize(),
		};

		this.View.autoPlace();
		this.History.push("autoplace", history);
	}

	/**
	 * Updates values of common types
	 * @param obj {Object} object with new values
	 * @param target {string} determines if it will be a type or a linkType
	 * @param init {Boolean} first call on initialization
	 */
	UpdateCommonValue(obj, target, init) {
		const history = {
			value: webix.copy(obj),
			prev: {},
			target,
		};

		for (let i in obj) {
			history.prev[i] = this.View[target][i];
			this.View[target][i] = obj[i];
		}
		if (!init) this.History.push("common", history);
		this.View.render();
	}

	/**
	 * Swap source and target of a link
	 * @param id {string} a link id
	 */
	SwapLinkDirection(id) {
		const link = this.View.getLinks().getItem(id);
		const update = {
			source: link.target,
			target: link.source,
		};
		if (link.from || link.to) {
			update.from = link.to;
			update.to = link.from;
		}
		if (webix.isArray(link.line)) update.line = link.line.slice().reverse();
		this.Local.updateLink(id, update);
	}

	/**
	 * Paints grid with the given step or hides it
	 * @param step {number} grid step ( defines step for resize and positioning)
	 * @param hide {boolean} defines whether grid should be hidden or shown
	 */
	DrawGrid(step, hide) {
		if (hide) this.View.$view.style.backgroundImage = "none";
		else {
			const svg = this.GridTemplate(step);
			this.View.$view.style.backgroundImage = `url('data:image/svg+xml;UTF8,${encodeURIComponent(
				svg
			)}')`;
			this.SetGridBgPosition();
		}
	}

	/**
	 * Sets grid background position depending on scroll state
	 */
	SetGridBgPosition() {
		const step = this.State.gridStep,
			c = this.View.config;
		const { x, y } = this.View.getScrollState();
		const left = -x + ((c.paddingX || c.padding) % step);
		const top = -y + ((c.paddingY || c.padding) % step);
		this.View.$view.style.backgroundPosition = left + "px " + top + "px";
	}

	/**
	 * Applies zoom value to the workspace
	 * @param z {number} a new zoom
	 * @param oldZoom {number} an old zoom value
	 */
	Zoom(z) {
		// add scale css
		this.View.$view.className = this.View.$view.className.replace(
			/ webix_de_scale_\d+/,
			""
		);
		webix.html.addCss(this.View.$view, this.GetScaleCss(z));
		// apply "zoom" to config and redraw the diagram
		this.View.config.zoom = z;
		this.View.render();
	}

	/**
	 * Returns css based on current zoom
	 * @param z {number} current zoom
	 * @return {string} css
	 */
	GetScaleCss(z) {
		let s;
		if (z <= 0.3) s = "03";
		else if (z <= 0.5) s = "05";
		else if (z < 0.9) s = "07";
		else s = Math.round(z);
		return "webix_de_scale_" + s;
	}

	/**
	 * Gets an item object by its id
	 * @param id {string} an item id
	 * @param isLink {boolean} specifies if an item is a link
	 * @returns {object} an item object
	 */
	GetItem(id, isLink) {
		const collection = isLink ? this.View.getLinks() : this.View;
		return collection.getItem(id);
	}

	/**
	 * Applies form changes to diagram
	 * @param obj {object} a hash of properties to change (ex. {fontColor: "#F4F5F9"})
	 * @param target {string} a name of a type target: "type" or "linkType"  (needed only for properties of "common" form)
	 */
	ApplyChanges(obj, target) {
		for (let name in obj) {
			const num = parseFloat(obj[name]);
			if (!isNaN(num)) obj[name] = num;
		}
		const selected = this.State.selected;
		if (!selected) this.UpdateCommonValue(obj, target);
		else if (selected.link) this.Local.updateLink(selected.id, obj);
		else this.Local.updateBlock(selected.id, obj);
	}

	/**
	 * Add hotkeys depending on a hotkey map (GetHotkeyData result)
	 */
	AddHotkeys() {
		const keys = this.GetHotkeyData();
		for (let i = 0; i < keys.length; ++i) {
			this.on(hotkey(), keys[i].key, (view, e) => {
				if (this.CheckHotkeyView(view)) {
					this.Action({ name: keys[i].action });
					webix.html.preventEvent(e);
				}
			});
		}
	}

	/**
	 * Gets hotkey map
	 * @returns {Array} an array of objects {key,action}
	 */
	GetHotkeyData() {
		if (!this._hotkeys) {
			const ctrlKey = webix.env.isMac ? "COMMAND" : "CTRL";
			this._hotkeys = [
				{ key: "DELETE", action: "remove" },
				{ key: "BACKSPACE", action: "remove" },
				{ key: `${ctrlKey}+C`, action: "copy" },
				{ key: `${ctrlKey}+V`, action: "paste" },
				{ key: `${ctrlKey}+Z`, action: "undo" },
				{ key: `${ctrlKey}+SHIFT+Z`, action: "redo" },
				{ key: `${ctrlKey}+Y`, action: "redo" },
			];
		}
		return this._hotkeys;
	}

	/**
	 * Check whether an active view should react on hotkey
	 * @param view {Object} an active view
	 * @returns {boolean} if true, hotkey action should be called
	 */
	CheckHotkeyView(view) {
		return view == this.View || view == this.Context.getRoot();
	}
}
