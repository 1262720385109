import shapes from "shapes";
import shapeTemplates from "shapes/templates";
import { drawLine } from "helpers/painter";
import { capitalize } from "helpers/styles";

export default class LocalData {
	constructor(app, data) {
		this.app = app;
		this._extraShapes = this.app.config ? this.app.config.shapes : data;
	}

	connect(view, history) {
		this._view = view;
		this._history = history;

		this._data = view.data;
		this._links = view.getLinks();
	}

	/**
	 * Return data
	 * @return {DataStore}
	 */
	data() {
		return this._data;
	}

	/**
	 * Return links
	 * @return {DataCollection}
	 */
	links() {
		return this._links;
	}

	/**
	 * Return shapes
	 * @return {DataCollection}
	 */
	shapes() {
		if (this._shapes) return this._shapes;
		this._shapes = new webix.DataCollection({
			scheme: {
				$change: obj => {
					let template = obj.template;
					if (typeof obj.square == "undefined" && typeof template == "string") {
						const baseTemplate = this.getShapeTemplate(template);
						if (baseTemplate && baseTemplate.square)
							obj.square = baseTemplate.square;
					}
					const name = typeof obj.name != "undefined" ? obj.name : obj.id;
					obj.name = capitalize(name);
				},
			},
		});

		// add default shapes to collection
		this._shapes.parse(shapes);

		if (webix.isArray(this._extraShapes)) {
			let index = -1;
			this._extraShapes.forEach(s => {
				if (s.id && this._shapes.getItem(s.id)) {
					this._shapes.updateItem(s.id, s);
				} else {
					s.group = s.group || "Extra";
					index++;
					return this._shapes.add(s, index);
				}
			});
		}
		return this._shapes;
	}

	/**
	 * Get a shape template object by its name or the hash of all templates
	 * @param name {string} a template name (optional)
	 * @returns {object|array}
	 */
	getShapeTemplate(name) {
		return name === true ? shapeTemplates : shapeTemplates[name];
	}

	/**
	 * Adds single link to history state object
	 * @param link {Object} a link data object
	 * @param history {Object} history state object
	 * @param linkCache {Object} cache of already saved links
	 */
	addLinkToHistory(link, history, linkCache) {
		if (!linkCache[link.id]) {
			linkCache[link.id] = true;
			history.links.push(webix.copy(link));
		}
	}

	/**
	 * Updates all custom links associated with the block
	 * @param id {number|string} a block id
	 * @param history {Object} history state object
	 * @param linkCache {Object} cache of already saved links
	 * @return {Boolean} something was found
	 */
	updateCustomLinks(id, history, linkCache) {
		let render = false;
		this._links
			.find(a => a.source == id || a.target == id)
			.forEach(link => {
				if (webix.isArray(link.line)) {
					this.addLinkToHistory(link, history, linkCache);
					link.line = drawLine(this._view, link, link.from, link.to);
					render = true;
				}
			});

		return render;
	}

	/**
	 * Shifts data and updates custom links if the block is outside the work area (x\y < 0)
	 * @param id {number|string} a block id
	 * @param history {Object} history state object
	 * @param linkCache {Object} cache of already saved links
	 * @return {Boolean} something was found
	 */
	shiftData(id, history, linkCache) {
		const item = this._data.getItem(id);

		const x = Math.min(0, item.x);
		const y = Math.min(0, item.y);
		if (x < 0 || y < 0) {
			history.data = this._data.serialize();
			this._data.each(
				obj => {
					obj.x -= x;
					obj.y -= y;
				},
				this,
				true
			);

			this._links.data.each(
				link => {
					if (webix.isArray(link.line)) {
						this.addLinkToHistory(link, history, linkCache);
						link.line = link.line.map(a => {
							a = webix.isArray(a) ? a : a.split(",");
							return [a[0] - x, a[1] - y];
						});
					}
				},
				this,
				true
			);
			return true;
		}
	}

	/**
	 * Adds new block
	 * @param obj {Object} new block data
	 * @return {number|string} id of the new block
	 */
	addBlock(obj) {
		const history = {
			value: webix.copy(obj),
			prev: null,
			links: [],
		};
		const nid = this._data.add(obj);
		this._history.push("add", history, nid);
		history.value.id = nid;

		if (this.shiftData(nid, history, {})) this._view.render();

		return nid;
	}

	/**
	 * Adds new link
	 * @param obj {Object} new link data
	 * @return {number|string} id of the new link
	 */
	addLink(obj) {
		const history = {
			value: webix.copy(obj),
			prev: null,
		};

		const nid = this._links.add(obj);
		this._history.push("add", history, nid, true);
		history.value.id = nid;

		return nid;
	}

	/**
	 * Removes block
	 * @param id {number|string} a block id
	 */
	removeBlock(id) {
		const history = {
			value: null,
			prev: webix.copy(this._data.getItem(id)),
			links: [],
		};

		this._view.$blockRender = true;
		this._links
			.find(a => a.source == id || a.target == id)
			.forEach(link => {
				history.links.push(webix.copy(link));
				this._links.remove(link.id);
			});
		this._view.$blockRender = false;

		this._data.remove(id);
		this._history.push("remove", history, id);
	}

	/**
	 * Removes link
	 * @param id {number|string} a link id
	 */
	removeLink(id) {
		const history = {
			value: null,
			prev: webix.copy(this._links.getItem(id)),
		};

		this._links.remove(id);
		this._history.push("remove", history, id, true);
	}

	/**
	 * Updates block data
	 * @param id {number|string} a block id
	 * @param obj {Object} object with new values
	 */
	updateBlock(id, obj) {
		const history = {
			value: webix.copy(obj),
			prev: webix.copy(this._data.getItem(id)),
			links: [],
		};
		this._data.updateItem(id, obj);
		this._history.push("update", history, id);

		const linkCache = {};
		let render = this.updateCustomLinks(id, history, linkCache);
		if (this.shiftData(id, history, linkCache)) render = true;

		if (render) this._view.render();
	}

	/**
	 * Updates link data
	 * @param id {number|string} a link id
	 * @param obj {Object} object with new values
	 */
	updateLink(id, obj) {
		if (obj.mode) obj.line = obj.from = obj.to = undefined;

		const history = {
			value: webix.copy(obj),
			prev: webix.copy(this._links.getItem(id)),
		};

		this._links.updateItem(id, obj);
		this._history.push("update", history, id, true);
	}
}
