export default [
	{
		id: "default",
		template: "default",
		group: "common",
	},
	{
		id: "org",
		template: "org",
		group: "common",
	},
	{
		id: "text",
		template: "text",
		group: "common",
	},
	{
		id: "rrect",
		template: "rrect",
		group: "block",
	},
	{
		id: "ellipse",
		template: "ellipse",
		group: "block",
	},
	{
		id: "circle",
		template: "circle",
		group: "block",
	},
	{
		id: "square",
		template: "square",
		group: "block",
	},
	{
		id: "triangle",
		template: "triangle",
		group: "block",
	},
	{
		id: "dot",
		template: "dot",
		group: "block",
	},
	{
		id: "trapezoid",
		template: "trapezoid",
		group: "block",
	},
	{
		id: "head",
		template: "head",
		group: "block",
	},
	{
		id: "pentagon",
		template: "pentagon",
		group: "block",
	},
	{
		id: "octagon",
		template: "octagon",
		group: "block",
	},
	{
		id: "heptagon",
		template: "heptagon",
		group: "block",
	},
	{
		id: "plus",
		template: "plus",
		group: "block",
	},
	{
		id: "star",
		template: "star",
		group: "block",
	},
	{
		id: "rtriangle",
		template: "rtriangle",
		group: "block",
	},
	{
		id: "tail",
		template: "tail",
		group: "block",
	},
	{
		id: "collate",
		template: "collate",
		group: "flow",
	},
	{
		id: "connector",
		template: "connector",
		group: "flow",
	},
	{
		id: "data",
		template: "data",
		group: "flow",
	},
	{
		id: "database",
		template: "database",
		group: "flow",
	},
	{
		id: "decision",
		template: "decision",
		group: "flow",
	},
	{
		id: "delay",
		template: "delay",
		group: "flow",
	},
	{
		id: "display",
		template: "display",
		group: "flow",
	},
	{
		id: "document",
		template: "document",
		group: "flow",
	},
	{
		id: "multidoc",
		template: "multidoc",
		group: "flow",
	},
	{
		id: "junction",
		template: "junction",
		group: "flow",
	},
	{
		id: "input",
		template: "input",
		group: "flow",
	},
	{
		id: "internal",
		template: "internal",
		group: "flow",
	},
	{
		id: "looplimit",
		template: "looplimit",
		group: "flow",
	},
	{
		id: "note",
		template: "note",
		group: "flow",
	},
	{
		id: "merge",
		template: "merge",
		group: "flow",
	},
	{
		id: "operation",
		template: "operation",
		group: "flow",
	},
	{
		id: "or",
		template: "or",
		group: "flow",
	},
	{
		id: "output",
		template: "output",
		group: "flow",
	},
	{
		id: "preparation",
		template: "preparation",
		group: "flow",
	},
	{
		id: "process",
		template: "process",
		group: "flow",
	},
	{
		id: "sdata",
		template: "sdata",
		group: "flow",
	},
	{
		id: "sort",
		template: "sort",
		group: "flow",
	},
	{
		id: "start",
		template: "start",
		group: "flow",
	},
	{
		id: "storage",
		template: "storage",
		group: "flow",
	},
	{
		id: "subroutine",
		template: "subroutine",
		group: "flow",
	},
	{
		id: "tape",
		template: "tape",
		group: "flow",
	},
];
