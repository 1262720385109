webix.protoUI(
	{
		name: "diagram-icon-toggle",
		$cssName: "icon",
		defaults: {
			icons: ["dgi-shape-square-plus", "dgi-ray-start-arrow"],
		},
		$init: function() {
			this.attachEvent("onItemClick", function() {
				this.toggle("user");
			});
		},
		$prepareValue(value) {
			const iconCss = (this.config.icon = this.config.icons[value ? 1 : 0]);
			const icon = this.$view.querySelector(".webix_icon");
			if (icon) icon.className = "webix_icon " + iconCss;
			return value;
		},
		toggle: function(config) {
			this.setValue(!this.getValue(), config);
		},
	},
	webix.ui.icon
);
