function drawDefaultLine(view, source, target, mode) {
	const s = getPoint(view, source, "center");
	const e = getPoint(view, target, "center");

	mode = mode || view.linkType.mode;
	if (mode == "direct" || s[1] == e[1]) {
		return [[s[0], s[1]], [e[0], e[1]]];
	} else {
		const dy = Math.floor((e[1] - s[1]) / 2);
		return [[s[0], s[1]], [s[0], s[1] + dy], [e[0], s[1] + dy], [e[0], e[1]]];
	}
}

function drawCustomLine(view, source, target, link) {
	const result = webix.copy(link.line);
	if (link.from) result[0] = getPoint(view, source, link.from);
	if (link.to) result[result.length - 1] = getPoint(view, target, link.to);
	return result;
}

function isVertical(from, to) {
	return from != "left" && from != "right" && to != "left" && to != "right";
}

function isHorisontal(from, to) {
	return from != "top" && from != "bottom" && to != "top" && to != "bottom";
}

function getAngle(view, item) {
	let angle = item["angle"];
	if (webix.isUndefined(angle)) angle = view.getItemValue(item.id, "angle");
	return 1 * angle || 0;
}

const rotation = {
	right: 0,
	bottom: 90,
	left: 180,
	top: 270,
};

function correctAngle(angle, mode) {
	return (((angle + rotation[mode]) % 360) * Math.PI) / 180;
}

function correctDirection(angle, mode) {
	angle = (angle + rotation[mode]) % 360;
	const dirr = Math.floor((angle - 45) / 90);
	return (dirr < 3 ? dirr : 0) + 2;
}

function shiftPoint(p, mode, angle, diff) {
	if (angle) {
		angle = correctAngle(angle, mode);
		return [
			Math.round(p[0] + diff * Math.cos(angle)),
			Math.round(p[1] + diff * Math.sin(angle)),
		];
	} else if (mode == "left") {
		return [p[0] - diff, p[1]];
	} else if (mode == "top") {
		return [p[0], p[1] - diff];
	} else if (mode == "bottom") {
		return [p[0], p[1] + diff];
	} else if (mode == "right") {
		return [p[0] + diff, p[1]];
	}
}

function updateRoute(route, s, e, from, to) {
	// one of the points is the center
	if (from * to == 0) {
		if (from == 2 || from == 4 || to == 1 || to == 3) [s, e] = [e, s];
		return route.push([s[0], e[1]]);
	}
	// the same sides
	else if (from == to) {
		if ((from == 1 && s[0] < e[0]) || (from == 3 && s[0] > e[0])) {
			return route.push([e[0], s[1]]);
		} else if ((from == 2 && s[1] > e[1]) || (from == 4 && s[1] < e[1])) {
			return route.push([e[0], s[1]]);
		}
	}
	// opposite sides
	else if (Math.abs(from - to) == 2) {
		if ((from == 1 && s[0] > e[0]) || (from == 3 && s[0] < e[0])) {
			const p = (s[1] + e[1]) / 2;
			return route.push([s[0], p], [e[0], p]);
		} else if ((from == 2 && s[1] > e[1]) || (from == 4 && s[1] < e[1])) {
			const p = (s[0] + e[0]) / 2;
			return route.push([p, s[1]], [p, e[1]]);
		}
	}
	// perpendicular sides
	else if (from == 1 && s[0] < e[0]) {
		if ((to == 2 && s[1] > e[1]) || (to == 4 && s[1] < e[1]))
			return route.push([e[0], s[1]]);
	} else if (from == 3 && s[0] > e[0]) {
		if ((to == 2 && s[1] > e[1]) || (to == 4 && s[1] < e[1]))
			return route.push([e[0], s[1]]);
	} else if (from == 2) {
		if (to == 3 && (s[0] > e[0] || s[1] > e[1]))
			return route.push([e[0], s[1]]);
		else if (to == 1 && !(s[0] >= e[0] && s[1] <= e[1]))
			return route.push([e[0], s[1]]);
	} else if (from == 4) {
		if (to == 1 && (s[0] < e[0] || s[1] < e[1]))
			return route.push([e[0], s[1]]);
		else if (to == 3 && !(s[0] <= e[0] && s[1] >= e[1]))
			return route.push([e[0], s[1]]);
	}

	// others
	return route.push([s[0], e[1]]);
}

/**
 * Calculates the path of the line
 * @param view {webix view} diagram
 * @param link {Object} link data object
 * @param from {string} defines the starting side
 * @param to {string} defines the end side
 * @param item {Object} custom block data for timelines
 * @return {Array}
 */
export function drawLine(view, link, from, to, item) {
	let source = view.getItem(link.source);
	let target = view.getItem(link.target);

	if (item && source.id == item.id) source = { ...source, ...item };
	else if (item && target.id == item.id) target = { ...target, ...item };

	from = from || "center";
	to = to || "center";

	if (webix.isArray(link.line))
		return drawCustomLine(view, source, target, link);
	else if (from == "center" && to == "center")
		return drawDefaultLine(view, source, target, link.mode);

	let s = getPoint(view, source, from); // start point
	let e = getPoint(view, target, to); // end point

	const sa = getAngle(view, source); // source angle
	const ta = getAngle(view, target); // target angle
	if (!sa && !ta) {
		// straight horizontal
		if (s[1] == e[1] && isHorisontal(from, to, sa, ta)) return [s, e];
		// straight vertical
		else if (s[0] == e[0] && isVertical(from, to, sa, ta)) return [s, e];
	}

	const sroute = [s];
	if (from != "center") {
		sroute.push((s = shiftPoint(s, from, sa, view.type.listMarginX / 2)));
		from = correctDirection(sa, from);
	} else from = 0;

	const eroute = [e];
	if (to != "center") {
		eroute.unshift((e = shiftPoint(e, to, ta, view.type.listMarginX / 2)));
		to = correctDirection(ta, to);
	} else to = 0;

	if (s[0] != e[0] && s[1] != e[1]) updateRoute(sroute, s, e, from, to);

	return sroute.concat(eroute);
}

function getItemSize(view, obj) {
	const shape = view.getShape(obj.type || view.type.type) || {};
	return {
		width: obj.width * 1 || shape.width || view.type.width,
		height: obj.height * 1 || shape.height || view.type.height,
	};
}

/**
 * Returns the position of a point in the format [x,y]
 * @param view {webix view} diagram
 * @param obj {Object} block data object
 * @param mode {string} defines the side
 * @param diff {number} additional offset
 * @return {Array}
 */
export function getPoint(view, obj, mode, diff) {
	const size = getItemSize(view, obj);
	diff = diff || 0;

	const cx = 1 * obj.x + size.width / 2;
	const cy = 1 * obj.y + size.height / 2;
	let angle = getAngle(view, obj);

	if (mode == "center") {
		return [cx, cy];
	} else if (angle) {
		const r =
			(mode == "left" || mode == "right" ? size.width : size.height) / 2 + diff;
		angle = correctAngle(angle, mode);
		return [
			Math.round(cx + r * Math.cos(angle)),
			Math.round(cy + r * Math.sin(angle)),
		];
	} else if (mode == "left") {
		return [1 * obj.x - diff, cy];
	} else if (mode == "top") {
		return [cx, 1 * obj.y - diff];
	} else if (mode == "bottom") {
		return [cx, 1 * obj.y + size.height + diff];
	} else if (mode == "right") {
		return [1 * obj.x + size.width + diff, cy];
	}
}
