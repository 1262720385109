function angleTemplate(obj, num) {
	const rx = obj.width / 2;
	const ry = obj.height / 2;
	const d = (obj.lineWidth || 1) / 2;
	const angle = (Math.PI * 2) / num;
	const cosA = Math.cos(angle);
	const sinA = Math.sin(angle);
	const len = Math.min(rx, ry) - 2 * d;
	let arr = [[num % 2 ? 0 : len * Math.tan(angle / 2), -len]];
	for (let i = 1; i < num; i++) {
		const x = arr[i - 1][0];
		const y = arr[i - 1][1];
		arr.push([x * cosA - y * sinA, x * sinA + y * cosA]);
	}
	const p = arr
		.map(p => {
			p[0] += rx;
			p[1] += ry;
			return p.join(",");
		})
		.join();
	return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
}
export default {
	default: {
		template:
			"<div class='webix_diagram_shape webix_diagram_shape_default' ></div>",
		svg: false,
	},
	org: {
		template: "<div class='webix_diagram_shape webix_diagram_shape_org'></div>",
		svg: false,
	},
	text: {
		template:
			"<div class='webix_diagram_shape webix_diagram_shape_text'></div>",
		svg: false,
	},
	rrect: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const minSize = Math.min(w, h);
			const d = (obj.lineWidth || 1) / 2;
			let r;
			if (typeof obj.borderRadius == "undefined") {
				r = Math.round(minSize / (Math.PI * 2));
				if (r < 3) r = minSize / 2;
			} else r = obj.borderRadius;
			return `<svg width='${obj.width}' height='${
				obj.height
			}'><rect x='${d}' y='${d}' height='${h - 2 * d}' width='${w -
				2 * d}'  rx='${r}' ry='${r}' ></rect></svg>`;
		},
	},
	ellipse: {
		svg: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const d = (obj.lineWidth || 1) / 2;
			return `<svg width='100%' height='100%'><ellipse cx='${rx}' cy='${ry}' rx='${rx -
				d}' ry='${ry - d}' ></ellipse></svg>`;
		},
	},
	circle: {
		square: true,
		svg: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const r = Math.min(rx, ry) - (obj.lineWidth || 1) / 2;
			return `<svg width='100%' height='100%'><ellipse cx='${rx}' cy='${ry}' rx='${r}' ry='${r}' ></ellipse></svg>`;
		},
	},
	square: {
		square: true,
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const size = Math.min(w, h) - (obj.lineWidth || 1);
			return `<svg width='100%' height='100%'><rect x='${w / 2 -
				size / 2}' y='${h / 2 -
				size / 2}' height='${size}' width='${size}' ></rect></svg>`;
		},
	},
	triangle: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = obj.lineWidth || 1;
			const p = [[w / 2, d], [w - d, h - d], [d, h - d]]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	dot: {
		svg: true,
		square: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const r = Math.min(rx, ry) - (obj.lineWidth || 1) / 2;
			const r0 = r * 0.6;
			return (
				`<svg width='100%' height='100%'><ellipse cx='${rx}' cy='${ry}' rx='${r}' ry='${r}'></ellipse>` +
				`<ellipse cx='${rx}' cy='${ry}' rx='${r0}' ry='${r0}' class='webix_diagram_shape_alt'></ellipse></svg>`
			);
		},
	},
	trapezoid: {
		svg: true,
		template: function(obj) {
			const width = obj.width;
			const height = obj.height;
			const d = (obj.lineWidth || 1) / 2 + 1;
			const dx = width / 8;
			const p = [
				[dx + d, d],
				[width - dx - d, d],
				[width - d, height - d],
				[d, height - d],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},

	head: {
		svg: true,
		template: function(obj) {
			const h = obj.height;
			const w = obj.width;
			const d = obj.lineWidth || 1;
			const dx = w / 8;
			const p = [[d, d], [w - d, h / 2], [d, h - d], [dx + d, h / 2]]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},

	pentagon: {
		svg: true,
		square: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const d = (obj.lineWidth || 1) / 2;
			const len = Math.min(obj.width, obj.height) - 2 * d;
			const a = (Math.PI * 18) / 180;
			const h = len * Math.cos(a);
			const dh = ry - h / 2;
			const r = len / 2 / Math.cos(a);
			const dy = r * Math.sin(a);
			const dx = r * Math.cos(a);

			const dx0 = h * Math.tan(a);

			const p = [
				[rx, dh],
				[rx + dx, ry + dh - dy],
				[rx + dx0, h + dh],
				[rx - dx0, h + dh],
				[rx - dx, ry + dh - dy],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	octagon: {
		svg: true,
		square: true,
		template: obj => angleTemplate(obj, 8),
	},
	heptagon: {
		svg: true,
		square: true,
		template: obj => angleTemplate(obj, 7),
	},
	plus: {
		square: true,
		svg: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const r = Math.min(rx, ry) - (obj.lineWidth || 1) / 2;
			const d = r / 6;
			const p = [
				[rx - d, ry - r],
				[rx + d, ry - r],
				[rx + d, ry - d],
				[rx + r, ry - d],
				[rx + r, ry + d],
				[rx + d, ry + d],
				[rx + d, ry + r],
				[rx - d, ry + r],
				[rx - d, ry + d],
				[rx - r, ry + d],
				[rx - r, ry - d],
				[rx - d, ry - d],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	star: {
		svg: true,
		square: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const d = obj.lineWidth || 1;
			const len = Math.min(obj.width, obj.height) - 2 * d;

			const a = (Math.PI * 18) / 180;
			const h = len * Math.cos(a);
			const dh = ry - h / 2;
			const r = len / 2 / Math.cos(a);
			const dy = r * Math.sin(a);
			const dx = r * Math.cos(a);
			const dx0 = h * Math.tan(a);
			const dy1 = (len / 2) * Math.tan((Math.PI * 36) / 180);
			const dx1 = dy1 * Math.tan(a);
			const dx2 = (len / 2 + dx1) * Math.sin(a);
			const dy2 = (len / 2 + dx1) * Math.cos(a);
			const dy3 = h - dx0 * Math.tan((Math.PI * 36) / 180);
			const p = [
				[rx, dh],
				[rx + dx1, dh + dy1],
				[rx + dx, r - dy + dh],
				[rx + dx2, dy2 + dh],
				[rx + dx0, h + dh],
				[rx, dy3 + dh],
				[rx - dx0, h + dh],
				[rx - dx2, dy2 + dh],
				[rx - dx, r - dy + dh],
				[rx - dx1, dy1 + dh],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	rtriangle: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = obj.lineWidth || 1;

			const p = [[d, d], [w - d, h - d], [d, h - d]]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	tail: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2 + 1;
			const dx = w / 6;
			const p = [
				[d, d],
				[w - dx - d, d],
				[w - d, h / 2],
				[w - dx - d, h - d],
				[d, h - d],
				[d + dx, h / 2],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	collate: {
		svg: true,
		square: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = obj.lineWidth || 1;
			const p = [
				[d, d],
				[w - d, d],
				[w / 2, h / 2],
				[w - d, h - d],
				[d, h - d],
				[w / 2, h / 2],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	connector: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const p = [[d, d], [w - d, d], [w - d, h / 2], [w / 2, h - d], [d, h / 2]]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	data: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dx = Math.round(w / 10);
			const path =
				`M ${d + dx} ${d} H ${w - d - dx} C ${w} ${d}, ${w} ${h - d}, ${w -
					d -
					dx} ${h - d} H ${d + dx} ` + ` C 0 ${h - d} , 0 ${d}, ${d + dx} ${d}`;
			const path2 = `M ${w - d - dx} ${d} C ${w - d - 2 * dx} ${d} , ${w -
				d -
				2 * dx} ${h - d}, ${w - d - dx} ${h - d}`;
			return `<svg width='100%' height='100%'><path d='${path}'></path><path d='${path2}'></path></svg>`;
		},
	},
	database: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dy = Math.round(h / 6);
			const path =
				`M ${d} ${d + dy}  C 0 ${d}, ${w - d} 0, ${w - d} ${d + dy} V ${h -
					d -
					dy}` + ` C ${w - d} ${h}, ${d} ${h}, ${d} ${h - d - dy} Z`;
			const path2 = `M ${d} ${d + dy} C ${d} ${d + 2 * dy}, ${w - d} ${d +
				2 * dy}, ${w - d} ${d + dy}`;
			return `<svg width='100%' height='100%'><path d='${path}'></path><path d='${path2}'></path></svg>`;
		},
	},
	decision: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = obj.lineWidth || 1;
			const p = [[w / 2, d], [w - d, h / 2], [w / 2, h - d], [d, h / 2]]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	delay: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dx = h / 2 - d;
			const path = `M ${d} ${d} H  ${w - d - dx} A ${dx} ${dx}, 0 0, 1 ${w -
				d -
				dx} ${h - d} H ${d} Z`;
			return (
				"<svg width='100%' height='100%'><path d='" + path + "'></path></svg>"
			);
		},
	},
	display: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dx = Math.min(w, h) / 2 - d;
			const path =
				`M ${d + dx} ${d} H  ${w - d - dx} A ${dx} ${dx}, 0 0, 1 ${w -
					d -
					dx} ${h - d} H ${d + dx}` + ` L ${d} ${h / 2} Z`;
			return (
				"<svg width='100%' height='100%'><path d='" + path + "'></path></svg>"
			);
		},
	},
	document: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dy = Math.round(h / 6);
			const path =
				`M ${d} ${d} H ${w - d} V ${h - d - dy} Q ${(3 * w) / 4 - d} ${h -
					d -
					2 * dy -
					dy / 3},` +
				`${w / 2} ${h - d - dy} Q ${w / 4} ${h + dy / 3}, ${d} ${h -
					d -
					dy} V ${d} Z `;
			return (
				"<svg width='100%' height='100%'><path d='" + path + "'></path></svg>"
			);
		},
	},
	multidoc: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dy = Math.round(h / 6);
			const d0 = Math.min(Math.min(w, h) / 12, 5);
			const path =
				`M ${d} ${d + d0 * 2} H ${w - d - d0 * 2} V ${h - d - dy} Q ${(3 * w) /
					4 -
					d} ${h - d - 2 * dy - dy / 3},` +
				`${w / 2} ${h - d - dy} Q ${w / 4} ${h + dy / 3}, ${d} ${h -
					d -
					dy} V ${d + d0 * 2}` +
				` H ${d + d0} V ${d + d0} H ${w - d - d0} V ${h - d - dy - d0}  L ${w -
					d -
					d0 * 2} ${h - d - dy - (d0 * 3) / 2}` +
				`V ${d + d0 * 2} H ${d + d0} V ${d + d0}  H ${d +
					d0 * 2} V ${d}  H ${w - d} V ${h - d - dy - d0 * 2}` +
				`L ${w - d - d0} ${h - d - dy - (d0 * 5) / 2}`;
			return (
				"<svg width='100%' height='100%'><path d='" + path + "'></path></svg>"
			);
		},
	},
	junction: {
		svg: true,
		square: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const r = Math.min(rx, ry) - (obj.lineWidth || 1) / 2;
			const h = (r * Math.sqrt(2)) / 2;
			return (
				`<svg width='100%' height='100%'><ellipse cx='${rx}' cy='${ry}' rx='${r}' ry='${r}' ></ellipse>` +
				`<path d='M ${rx - h} ${ry + h} L ${rx + h} ${ry - h}'></path>` +
				`<path d='M ${rx - h} ${ry - h} L ${rx + h} ${ry + h}'></path></svg>`
			);
		},
	},
	input: {
		svg: true,
		template: function(obj) {
			const width = obj.width;
			const height = obj.height;
			const d = (obj.lineWidth || 1) / 2 + 1;
			const dy = height / 3;
			const p = [
				[d, d + dy],
				[width - d, d],
				[width - d, height - d],
				[d, height - d],
			]
				.map(p => p.join(","))
				.join();
			return (
				"<svg width='100%' height='100%'><polygon points='" +
				p +
				"'></polygon></svg>"
			);
		},
	},
	internal: {
		svg: true,
		template: function(obj) {
			const width = obj.width;
			const height = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const size = Math.min(width, height) / 4;

			const rect = `<rect x='${d}' y='${d}' height='${height -
				2 * d}' width='${width - 2 * d}' ></rect>`;
			const lines = `<path d='M ${d} ${d + size} H ${width -
				d}'></path><path d='M ${d + size} ${d} V ${height - d}'></path>`;
			return "<svg width='100%' height='100%'>" + rect + lines + "</svg>";
		},
	},
	looplimit: {
		svg: true,
		template: function(obj) {
			const width = obj.width;
			const height = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dx = width / 6;
			const p = [
				[d + dx, d],
				[width - d - dx, d],
				[width - d, d + dx],
				[width - d, height - d],
				[d, height - d],
				[d, d + dx],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	merge: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = obj.lineWidth || 1;
			const p = [[d, d], [w - d, d], [w / 2, h - d]]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	note: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = obj.lineWidth || 1;
			const minSize = Math.min(w, h);
			let r;
			if (typeof obj.borderRadius == "undefined") {
				r = Math.min(Math.round(minSize / 12), 5);
			} else r = obj.borderRadius;
			const dx = (minSize * 2) / 5;
			const path =
				`M ${w - d - dx} ${d} V ${d + dx - r} A ${r} ${r}, 0 0, 0 ${w -
					d -
					dx +
					r},${d + dx} H ${w - d} L ${w - d - dx} ${d}` +
				`H ${d + r} A ${r} ${r}, 0 0, 0 ${d},${d + r} V ${h -
					d -
					r} A ${r} ${r}, 0 0, 0 ${d + r},${h - d}` +
				`H ${w - d - r} A ${r} ${r}, 0 0, 0 ${w - d},${h - d - r} V ${d + dx} `;
			return (
				"<svg width='100%' height='100%'><path d='" + path + "'></path></svg>"
			);
		},
	},
	operation: {
		svg: true,
		template: function(obj) {
			const width = obj.width;
			const height = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dx = width / 8;
			const p = [
				[d, d],
				[width - d, d],
				[width - d - dx, height - d],
				[d + dx, height - d],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	or: {
		svg: true,
		square: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const r = Math.min(rx, ry) - (obj.lineWidth || 1) / 2;
			return (
				`<svg width='100%' height='100%'><ellipse cx='${rx}' cy='${ry}' rx='${r}' ry='${r}' ></ellipse>` +
				`<path d='M ${rx - r} ${ry}  L ${rx + r} ${ry}'></path>` +
				`<path d='M ${rx} ${ry - r} L ${rx} ${ry + r}'></path></svg>`
			);
		},
	},
	output: {
		svg: true,
		template: function(obj) {
			const width = obj.width;
			const height = obj.height;
			const d = (obj.lineWidth || 1) / 2 + 1;
			const dx = width / 8;
			const p = [
				[d + dx, d],
				[width - d, d],
				[width - d - dx, height - d],
				[d, height - d],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	preparation: {
		svg: true,
		template: function(obj) {
			const width = obj.width;
			const height = obj.height;
			const d = (obj.lineWidth || 1) / 2 + 1;
			const dx = width / 8;
			const p = [
				[d, height / 2],
				[d + dx, d],
				[width - d - dx, d],
				[width - d, height / 2],
				[width - d - dx, height - d],
				[d + dx, height - d],
				[d, height / 2],
			]
				.map(p => p.join(","))
				.join();
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon></svg>`;
		},
	},
	process: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			return `<svg width='100%' height='100%'><rect x='${d}' y='${d}' height='${h -
				2 * d}' width='${w - 2 * d}' ></rect></svg>`;
		},
	},
	sdata: {
		svg: true,
		square: true,
		template: function(obj) {
			const rx = obj.width / 2;
			const ry = obj.height / 2;
			const r = Math.min(rx, ry) - (obj.lineWidth || 1) / 2;
			const line = `<path d='M ${rx} ${ry + r} H ${rx + r} Z'></path>`;
			return `<svg width='100%' height='100%'><ellipse cx='${rx}' cy='${ry}' rx='${r}' ry='${r}' ></ellipse>${line}</svg>`;
		},
	},
	sort: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = obj.lineWidth || 1;
			const p = [[w / 2, d], [w - d, h / 2], [w / 2, h - d], [d, h / 2]]
				.map(p => p.join(","))
				.join();
			const line =
				"<path d='M " + d + " " + h / 2 + " H " + (w - 2 * d) + " Z'></path>";
			return `<svg width='100%' height='100%'><polygon points='${p}'></polygon>${line}</svg>`;
		},
	},
	start: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dx = h / 2 - d;
			const path =
				`M ${d + dx} ${d} H ${w - d - dx} A ${dx} ${dx}, 0 0, 1 ${w -
					d -
					dx} ${h - d}` + ` H ${d + dx} A ${dx} ${dx}, 0 0, 1 ${d + dx} ${d} Z`;
			return (
				"<svg width='100%' height='100%'><path d='" + path + "'></path></svg>"
			);
		},
	},
	storage: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dx = Math.round(w / 10);
			const path =
				`M ${d + dx} ${d} H  ${w - d} C ${w - d - dx} ${d}, ${w - d - dx} ${h -
					d}, ${w - d} ${h - d} ` +
				`H ${d + dx} C 0 ${h - d}, 0 ${d}, ${d + dx} ${d} Z`;
			return (
				"<svg width='100%' height='100%'><path d='" + path + "'></path></svg>"
			);
		},
	},
	subroutine: {
		svg: true,
		template: function(obj) {
			const width = obj.width;
			const height = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const size = Math.min(width, height) / 4;

			const rect = `<rect x='${d}' y='${d}' height='${height -
				2 * d}' width='${width - 2 * d}' ></rect>`;
			const lines =
				`<path d='M ${d + size} ${d} V ${height - d}'></path>` +
				`<path d='M ${width - d - size} ${d} V ${height - d}'></path>`;
			return "<svg width='100%' height='100%'>" + rect + lines + "</svg>";
		},
	},
	tape: {
		svg: true,
		template: function(obj) {
			const w = obj.width;
			const h = obj.height;
			const d = (obj.lineWidth || 1) / 2;
			const dy = Math.round(h / 6);
			const path =
				`M ${d} ${d + dy}  Q  ${w / 4 - d} ${d + 2 * dy + dy / 3}, ${w /
					2} ${d + dy}` +
				`Q  ${(3 * w) / 4} ${d - dy / 3}, ${w - d} ${d + dy} H  ${w - d} V ${h -
					d -
					dy}` +
				`Q  ${(3 * w) / 4 - d} ${h - d - 2 * dy - dy / 3} , ${w / 2} ${h -
					d -
					dy}` +
				`Q  ${w / 4} ${h + dy / 3}, ${d} ${h - d - dy} V ${d + dy} Z`;
			return (
				"<svg width='100%' height='100%'><path d='" + path + "'></path></svg>"
			);
		},
	},
};
