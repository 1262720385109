webix.protoUI(
	{
		name: "diagram-counter",
		$cssName: "counter webix_de_control_counter",
		$init: function() {
			this.attachEvent("onAfterRender", () => {
				this.$setValue(this.config.value);
				this.getInputNode().readOnly = true;
				webix.event(this.$view.firstChild, "keydown", e =>
					webix.html.preventEvent(e)
				);
			});
		},
		$setValue: function(value) {
			this.getInputNode().value = parseInt(value * 100, 10) + "%";
		},
		shift: function(step, config) {
			const v = this.config.value;
			const arr = this.config.numbers;
			const i = arr.indexOf(v) + step;
			this.setValue(arr[i] || v, config);
		},
	},
	webix.ui.counter
);
