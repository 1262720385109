import { JetView } from "webix-jet";

import workspace from "./workspace";
import toolbar from "./toolbar";
import shapes from "./shapes";
import form from "./form";

export default class EditorView extends JetView {
	config() {
		const editor = {
			localId: "editor",
			type: "wide",
			padding: { top: 4 },
			cols: [shapes, workspace, { $subview: form, name: "form" }],
		};

		return {
			rows: [toolbar, editor],
		};
	}

	init() {
		this.on(this.app, "toolbar:preview", v => {
			const parts = this.$$("editor").getChildViews();
			if (v) {
				parts[0].hide();
				parts[2].hide();
			} else {
				parts[0].show();
				parts[2].show();
			}
		});
	}
}
