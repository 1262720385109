webix.protoUI(
	{
		name: "diagram-grid",
		$cssName: "text webix_diagram_control_text",
		$init: function() {
			this._inputSpacing = webix.skin.$active.inputSpacing;
			this.attachEvent("onAfterRender", () => {
				this.getInputNode().min = this.config.min;
			});
		},
		defaults: {
			min: 0,
			icon: true,
			type: "number",
		},
		$prepareValue: function(value) {
			return Math.abs(parseFloat(value || 0));
		},
		$renderIcon: function(c) {
			const height = c.aheight - 2 * c.inputPadding;
			const padding = (height - 18) / 2 - 1;
			let right = this._inputSpacing / 2;
			let html = "";

			if (c.inputLabel) {
				html +=
					"<span style='right:" +
					right +
					"px;height:" +
					(height - padding) +
					"px;' class='webix_de_input_label'><span>" +
					c.inputLabel +
					"</span></span>";
			}
			return html;
		},
	},
	webix.ui.text
);
webix.protoUI(
	{
		name: "diagram-text",
		$allowsClear: false,
		$init: function() {
			this.attachEvent("onBlur", this._applyChanges);
			this.attachEvent("onEnter", this._applyChanges);
		},
		_applyChanges: function() {
			let value = this.getValue();
			const max = this.config.max,
				min = this.config.min;
			if (value * 1 < min) {
				value = min;
				this.getInputNode().value = value;
			}
			if (max && value * 1 > max) {
				value = max;
				this.getInputNode().value = value;
			}
			this.setValue(value, "user");
		},
	},
	webix.ui["diagram-grid"]
);
