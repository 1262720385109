function updateDragPos(e, pos) {
	if (webix.DragControl.active) {
		const node = webix.DragControl.getNode();

		pos = webix.copy(pos);
		webix.DragControl.$dragPos(pos, e);

		node.style.top = pos.y + webix.DragControl.top + "px";
		node.style.left = pos.x + webix.DragControl.left + "px";
	}
}

const DragScroll = {
	start(ctx, e) {
		ctx._auto_scroll_delay = webix.delay(
			DragScroll.autoScroll,
			ctx,
			[e, webix.html.pos(e)],
			250
		);
	},

	reset(ctx, final) {
		if (ctx._auto_scroll_delay)
			ctx._auto_scroll_delay = window.clearTimeout(ctx._auto_scroll_delay);

		if (final && ctx.snode) {
			ctx.snode.style.minWidth = "";
			ctx.snode.style.minHeight = "";
			ctx.from.callEvent("onAfterAutoScroll", []);
		}
	},

	autoScroll(e, pos) {
		const yScroll = this.direction.indexOf("y") !== -1;
		const xScroll = this.direction.indexOf("x") !== -1;
		const box = webix.html.offset(this.from.$view);

		let reset = false;
		if (yScroll && DragScroll.autoYScroll.call(this, pos, box, this.senseY))
			reset = true;
		if (xScroll && DragScroll.autoXScroll.call(this, pos, box, this.senseX))
			reset = true;

		if (reset) {
			updateDragPos(e, pos);
			this._auto_scroll_delay = webix.delay(
				DragScroll.autoScroll,
				this,
				[e, pos],
				100
			);
		}
	},

	autoYScroll(pos, box, sense) {
		const scroll = this.from.getScrollState();

		if (pos.y < box.y + sense) {
			return DragScroll.autoScrollTo.call(
				this,
				scroll.x,
				scroll.y - sense / 2,
				"y"
			);
		} else if (pos.y > box.y + box.height - sense) {
			return DragScroll.autoScrollTo.call(
				this,
				scroll.x,
				scroll.y + sense / 2,
				"y"
			);
		}
		return false;
	},

	autoXScroll(pos, box, sense) {
		const scroll = this.from.getScrollState();

		if (pos.x < box.x + sense) {
			return DragScroll.autoScrollTo.call(
				this,
				scroll.x - sense / 2,
				scroll.y,
				"x"
			);
		} else if (pos.x > box.x + box.width - sense) {
			return DragScroll.autoScrollTo.call(
				this,
				scroll.x + sense / 2,
				scroll.y,
				"x"
			);
		}
		return false;
	},

	autoScrollTo(x, y, mode) {
		if (this.snode) {
			this.snode.style.minWidth =
				(this.from.$width + Math.ceil(x)) / this.zoom + "px";
			this.snode.style.minHeight =
				(this.from.$height + Math.ceil(y)) / this.zoom + "px";
		}

		this.from.scrollTo(x, y);
		this.from.callEvent("onAfterAutoScroll", []);

		const scroll = this.from.getScrollState();
		return Math.abs((mode === "x" ? x : y) - scroll[mode]) < 1;
	},
};

export default DragScroll;
