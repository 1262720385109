export default {
	"triangle-fill":
		"<svg width='35' height='10' viewBox='0 0 35 10' fill='none' xmlns='http://www.w3.org/2000/svg'>\n" +
		"<path d='M35 5L24.9632 9.33013L24.9632 0.669873L35 5Z' fill='#94A1B3'/>" +
		"<path d='M0 5L32.5746 5' stroke='#94A1B3'/>" +
		"</svg>",
	angle:
		"<svg width='36' height='8' viewBox='0 0 36 8' fill='none' xmlns='http://www.w3.org/2000/svg'>" +
		"<path d='M35.3536 4.35356C35.5488 4.15829 35.5488 3.84171 35.3536 3.64645L32.1716 0.464469C31.9763 0.269207 31.6597 0.269206 31.4645 0.464469C31.2692 0.659731 31.2692 0.976313 31.4645 1.17158L34.2929 4L31.4645 6.82843C31.2692 7.02369 31.2692 7.34027 31.4645 7.53554C31.6597 7.7308 31.9763 7.7308 32.1716 7.53554L35.3536 4.35356ZM-4.09794e-08 4.5L35 4.5L35 3.5L4.09794e-08 3.5L-4.09794e-08 4.5Z' fill='#94A1B3'/>" +
		"</svg>",
	triangle:
		"<svg width='35' height='10' viewBox='0 0 35 10' fill='none' xmlns='http://www.w3.org/2000/svg'>\n" +
		"<path d='M0 5L32.5746 5' stroke='#94A1B3'/>" +
		"<path d='M35 5L24.9632 9.33013L24.9632 0.669873L35 5Z' class='webix_diagram_arrow_icon' fill='#fff'  stroke='#94A1B3' />" +
		"</svg>",
	solid:
		"<svg width='74' height='8' viewBox='0 0 90 8' fill='none' xmlns='http://www.w3.org/2000/svg'>" +
		"<path d='M0 4.857117L80 4.857117' stroke='#94A1B3'/>" +
		"</svg>",
	dashed:
		"<svg width='74' height='8' viewBox='0 0 90 8' fill='none' xmlns='http://www.w3.org/2000/svg'>" +
		"<path d='M0 4.857117L80 4.857117' stroke='#94A1B3' stroke-dasharray='4,4'/>" +
		"</svg>",
	dotted:
		"<svg width='74' height='8' viewBox='0 0 90 8' fill='none' xmlns='http://www.w3.org/2000/svg'>" +
		"<path d='M0 4.857117L80 4.857117' stroke='#94A1B3' stroke-dasharray='2,2'/>" +
		"</svg>",
};
