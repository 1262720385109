webix.protoUI(
	{
		name: "diagram-toggle",
		$cssName: "toggle webix_de_toggle",
		$prepareValue: function(value) {
			if (!value || value === 1 || value === true) return value;
			return value === this.config.onValue ? 1 : 0;
		},
		getValue: function() {
			return this.config.value ? this.config.onValue : this.config.offValue;
		},
		toggle: function(config) {
			this.setValue(!this.config.value, config);
		},
	},
	webix.ui.toggle
);
