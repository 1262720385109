import { JetView } from "webix-jet";
import "../helpers/dcounter";
import "../helpers/dtoggle";

export default class ToolbarView extends JetView {
	config() {
		this.State = this.app.getState();
		this._ = this.app.getService("locale")._;
		const padding = webix.skin.$active.layoutPadding.space;
		const margin = webix.skin.$active.layoutMargin.form;
		const ui = {
			view: "toolbar",
			localId: "toolbar",
			padding,
			elements: [
				{
					width: 430,
					margin: margin,
					cols: this.LeftGroupConfig(),
				},
				{},
				{
					margin: margin,
					cols: this.CenterGroupConfig(),
				},
				{},
				{
					margin: margin,
					width: 430,
					cols: this.RightGroupConfig(),
				},
			],
		};

		return ui;
	}

	init() {
		this.History = this.app.getService("history");

		this.on(this.app, "UndoChange", () => {
			this.$$("undo").define("disabled", !this.History.hasUndo());
			this.$$("redo").define("disabled", !this.History.hasRedo());
			this.$$("reset").define("disabled", this.History.state.length < 2);
		});
		this.SetValues();
	}

	/**
	 * Get an array of left layout buttons
	 * @returns {Array}
	 */
	LeftGroupConfig() {
		const state = this.State;
		const _ = this._;
		const skinConfig = webix.skin.$active;
		return [
			{
				view: "button",
				label: _("Reset"),
				localId: "reset",
				autowidth: true,
				click: () => this.History.reset(),
			},
			{
				view: "icon",
				icon: "dgi-undo",
				localId: "undo",
				click: () => this.History.undo(),
			},
			{
				view: "icon",
				icon: "dgi-redo",
				localId: "redo",
				click: () => this.History.redo(),
			},
			{
				view: "diagram-counter",
				name: "zoom",
				width: 108,
				numbers: this.GetZoomNumbers(),
				on: {
					onChange: (v, oldV, config) => {
						if (config == "user") state.zoom = v;
					},
				},
			},
			{
				view: "diagram-grid",
				css: "webix_de_control_grid",
				width: 80,
				name: "grid",
				min: 1,
				format: this.GridStepFormat(),
				inputLabel: "<span class='webix_icon dgi-grid'></span>",
				on: {
					onChange: (v, oldV, config) => {
						if (config == "user") state.gridStep = v;
					},
				},
			},
			{
				view: "diagram-toggle",
				name: "preview",
				css: "webix_de_control_preview",
				offLabel: "<span class='webix_icon dgi-preview'></span>",
				onLabel: "<span class='webix_icon dgi-preview-off'></span>",
				onValue: 1,
				offValue: 0,
				width: skinConfig.inputHeight - 2,
				on: {
					onChange: v => this.app.callEvent("toolbar:preview", [v]),
				},
			},
		];
	}
	/**
	 * Get an array of center layout buttons
	 * @returns {Array}
	 */
	CenterGroupConfig() {
		const _ = this._;
		return [
			{
				view: "button",
				label: _("Autoplace"),
				css: "webix_primary",
				autowidth: true,
				align: "center",
				click: () => this.app.callEvent("toolbar:autoplace", []),
			},
		];
	}
	/**
	 * Get an array of right layout buttons
	 * @returns {Array}
	 */
	RightGroupConfig() {
		const _ = this._;
		return [
			{},
			{
				view: "button",
				label: _("Apply"),
				css: "webix_primary",
				autowidth: true,
				align: "right",
				click: this.app.config.save,
			},
		];
	}

	/**
	 * Set toolbar values
	 */
	SetValues() {
		const state = this.State;
		this.$$("toolbar").setValues({
			zoom: state.zoom,
			grid: state.gridStep,
		});
	}

	/**
	 * Grid step format handlers
	 * @returns {Object} an object with edit and parse handlers
	 */
	GridStepFormat() {
		return {
			edit: v => parseInt(v, 10) || 1,
			parse: v => parseInt(v, 10) || 1,
		};
	}

	/**
	 * Gets an array of zoom values
	 * @returns {Array} an array of numbers
	 */
	GetZoomNumbers() {
		return [0.25, 0.5, 0.75, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 3, 4];
	}
}
