const styles = {
	fontColor: text => `color:${text};`,
	fontSize: text => `font-size:${text}px;`,
	fontFamily: text => `font-family:${text};`,
	fontWeight: text => `font-weight:${text};`,
	fontStyle: text => `font-style:${text};`,
	textAlign: text => {
		let value = "center";
		if (text == "left") value = "flex-start";
		else if (text == "right") value = "flex-end";
		return `justify-content:${value};text-align:${text};`;
	},
	textVAlign: text => {
		let value = "center";
		if (text == "top") value = "flex-start";
		else if (text == "bottom") value = "flex-end";
		return `align-items:${value};`;
	},
	angle: text => `transform:rotate(${text}deg);transform-origin:50% 50%;`,
	fillOpacity: (text, svg) =>
		svg ? `fill-opacity:${text};` : `opacity:${text};`,
	lineWidth: (text, svg) =>
		svg ? `stroke-width:${text};` : `border-width:${text}px;`,
	lineColor: (text, svg) => (svg ? `stroke:${text};` : `border-color:${text};`),
	lineStyle: (text, svg) => {
		let value = text;
		if (svg) {
			if (text == "dotted") value = "2,2";
			else if (text == "dashed") value = "4,4";
			else if (webix.isArray(text)) value = text.join(",");
			return `stroke-dasharray:${value};`;
		}
		return `border-style:${value};`;
	},
	backgroundColor: (text, svg) =>
		svg ? `fill:${text};` : `background:${text};`,
	altBackgroundColor: text => `fill:${text};`,
};

const svgModes = {
	svg: true,
	line: true,
	arrow: true,
};

const modes = {
	text: [
		"fontColor",
		"fontFamily",
		"fontSize",
		"fontWeight",
		"fontStyle",
		"textAlign",
		"textVAlign",
	],
	textBlock: ["fillOpacity"],
	rotation: ["angle"],
	block: [
		"fillOpacity",
		"lineWidth",
		"lineColor",
		"lineStyle",
		"backgroundColor",
	],
	alt: ["altBackgroundColor"],
	line: ["lineWidth", "lineColor", "lineStyle"],
	arrow: ["fillOpacity", "lineWidth", "lineColor", "backgroundColor"],
};
modes.svg = modes.block;

/**
 * Sets the first character to upper case
 * @param text {string} a text to capitalize
 * @returns {string}
 */
function capitalize(text) {
	return text.charAt(0).toUpperCase() + text.substring(1);
}

/**
 * Creates a string to apply as a value of "style" attribute for a shape or a link
 * @param obj {object} item properties
 * @param m {string} mode
 * @param shape {object} shape properties
 * @returns {string}
 */
function getStyle(obj, m, shape) {
	const props = modes[m] || [];
	shape = shape || {};

	let style = "";
	for (let i = 0; i < props.length; i++) {
		let p = props[i];
		let v = obj[p];
		if (webix.isUndefined(v)) v = shape[p];
		if (!webix.isUndefined(v) && v !== null) style += styles[p](v, svgModes[m]);
	}
	return style;
}

export { getStyle, capitalize };
