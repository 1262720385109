import m0 from "./views/editor";
import m1 from "./views/form";
import m2 from "./views/shapes";
import m3 from "./views/toolbar";
import m4 from "./views/windows/context";
import m5 from "./views/workspace";
import { JetView } from "webix-jet";

const views = { JetView };
views["editor"] = m0;
views["form"] = m1;
views["shapes"] = m2;
views["toolbar"] = m3;
views["windows/context"] = m4;
views["workspace"] = m5;

export default views;
