import { JetView } from "webix-jet";
import { getStyle, capitalize } from "../helpers/styles.js";

export default class ShapesView extends JetView {
	config() {
		this._ = this.app.getService("locale")._;
		this.State = this.app.getState();
		this.Local = this.app.getService("local");
		this.Shapes = this.Local.shapes();

		const skinConfig = this.webix.skin.$active;
		this.headerH = skinConfig.inputHeight - skinConfig.inputPadding * 2;

		const ui = {
			view: "scrollview",
			scroll: "auto",
			width: 260,
			body: {
				margin: 0,
				css: "webix_de_accordion",
				multi: true,
				rows: [],
			},
		};

		// get all existing groups (default+custom)
		const groups = [];
		this.Shapes.data.each(shape => {
			if (groups.indexOf(shape.group) === -1) groups.push(shape.group);
		});

		// create a dataview for each group

		groups.forEach(group => {
			const data = this.Shapes.find(shape => shape.group == group);
			ui.body.rows.push(this.GetPanelConfig(data, group));
		});

		return ui;
	}

	init() {
		this.AdjustDragPos();

		this.on(this.Shapes.data, "onStoreUpdated", () => this.refresh());
	}

	/**
	 * Define $dragPos handlers for all dataviews
	 */
	AdjustDragPos() {
		this.getRoot()
			.queryView("dataview", "all")
			.forEach(view => {
				// Centre alignment of a dragged shape regarding the cursor
				view.$dragPos = function(pos) {
					const node = webix.DragControl.getNode().firstChild;
					const size = webix.html.offset(node);
					pos.x = pos.x - Math.round(size.width / 2);
					pos.y = pos.y - Math.round(size.height / 2);
				};
			});
	}

	/**
	 * Get panel config
	 * @param data {array} group data (shapes)
	 * @param group {string} group name
	 * @returns {object} config object
	 */
	GetPanelConfig(data, group) {
		const header = this._(capitalize(group));
		return {
			header,
			headerHeight: this.headerH,
			headerAltHeight: this.headerH,
			css: "webix_de_panel",
			body: {
				padding: 10,
				cols: [this.GetDataviewConfig(data, group)],
			},
		};
	}
	/**
	 * Get dataview config
	 * @param data {array} group data (shapes)
	 * @param group {string} group name
	 * @returns {object} config object
	 */
	GetDataviewConfig(data, group) {
		return {
			view: "dataview",
			localId: `${group}View`,
			css: "webix_de_shapes",
			autoheight: true,
			template: (obj, common) => this.ItemTemplate(obj, common),
			tooltip: obj => {
				return obj.name ? this._(obj.name) : "";
			},
			xCount: 3,
			type: {
				width: 80,
				height: 50,
				css: "webix_diagram_item",
			},
			data,
			drag: "source",
			on: {
				onBeforeDrag: context => {
					context.mode = "add";
					context.html = this.CreateDnDShape(context);
					return true;
				},
			},
		};
	}

	/**
	 * Item template for a dataview
	 * @param obj {object} shape item object
	 * @param common {object} type properties
	 * @param size {object} an object with width and height (optional)
	 * @returns {string}
	 */
	ItemTemplate(obj, common, size) {
		let shapeStr = "",
			style;
		let shapeObj = {
			...obj,
			height: size ? size.height : common.height - 12,
			width: size ? size.width : common.width - 12,
		};
		delete shapeObj.name;

		if (!size) {
			if (obj.width < shapeObj.width) shapeObj.width = obj.width;
			if (obj.height < shapeObj.height) shapeObj.height = obj.height;
		}
		let template = obj.template;
		const baseTemplate = this.Local.getShapeTemplate(template);
		template = baseTemplate ? baseTemplate.template : template;

		shapeStr =
			typeof template == "function" ? template({ ...shapeObj }) : template;
		const isText = shapeStr.indexOf("webix_diagram_shape_text") != -1;
		if (isText)
			shapeStr = `<div class="webix_de_item_text">${this._(obj.name)}</div>`;
		let svg = (baseTemplate || obj).svg;
		if (typeof svg == "undefined")
			svg = shapeStr.toLowerCase().includes("<svg");
		if (svg) {
			style = getStyle(shapeObj, "svg", common);
			if (style)
				shapeStr = shapeStr.replace(/(<svg)/i, "$1 style='" + style + "'");

			style = getStyle(shapeObj, "alt", common);
			if (style)
				shapeStr = shapeStr.replace(
					/(class='webix_diagram_shape_alt')/gi,
					"$1 style='" + style + "'"
				);
		} else {
			style =
				(!isText ? getStyle(shapeObj, "block", common) : "") +
				getStyle(shapeObj, "text", common);
			if (style)
				shapeStr = shapeStr.replace(/(<\s*\w+)/i, "$1 style='" + style + "'");
		}
		return shapeStr;
	}

	/**
	 * Drag template for a shape
	 * @param context {object} drag context
	 * @returns {string} html string
	 */
	CreateDnDShape(context) {
		const shape = context.from.getItem(context.start);
		// match the default item size in Diagram view
		const common = this.app.getService("styles").getBlockValues();
		const width = shape.width || common.width;
		const height = shape.height || common.height;
		const shapeTile = `<div class="webix_diagram_item" style="width:${width}px;height:${height}px;line-height:${height}px;">
				${this.ItemTemplate(shape, context.from.type, { width, height })}
			</div>`;

		return shapeTile;
	}
}
