// require sync with css
const material = {
	block: {
		fontColor: "#475466",
		fontSize: 14,
		fontFamily: "Roboto",
		backgroundColor: "#F4F5F9",
		altBackgroundColor: "#CCD7E6",
		lineColor: "#CCD7E6",
	},
	line: {
		lineColor: "#CCD7E6",
	},
	arrow: {
		lineColor: "#CCD7E6",
		backgroundColor: "#FFF",
	},
};
const mini = {
	block: {
		fontColor: "#475466",
		fontSize: 12,
		fontFamily: "Roboto",
		backgroundColor: "#F4F5F9",
		altBackgroundColor: "#CCD7E6",
		lineColor: "#CCD7E6",
	},
	line: {
		lineColor: "#CCD7E6",
	},
	arrow: {
		lineColor: "#CCD7E6",
		backgroundColor: "#FFF",
	},
};
const compact = {
	block: {
		fontColor: "#475466",
		fontSize: 13,
		fontFamily: "PT Sans",
		backgroundColor: "#e3f2fd",
		altBackgroundColor: "#bbdefb",
		lineColor: "#bbdefb",
	},
	line: {
		lineColor: "#bbdefb",
	},
	arrow: {
		lineColor: "#bbdefb",
		backgroundColor: "#FFF",
	},
};
const flat = {
	block: {
		fontColor: "#475466",
		fontSize: 15,
		fontFamily: "PT Sans",
		lineColor: "#bbdefb",
		backgroundColor: "#e3f2fd",
		altBackgroundColor: "#bbdefb",
	},
	line: {
		lineColor: "#bbdefb",
	},
	arrow: {
		lineColor: "#bbdefb",
		backgroundColor: "#FFF",
	},
};
const contrast = {
	block: {
		fontColor: "#fff",
		fontSize: 15,
		fontFamily: "PT Sans",
		lineColor: "#ccd7e6",
		backgroundColor: "#393939",
		altBackgroundColor: "#ccd7e6",
	},
	line: {
		lineColor: "#ccd7e6",
	},
	arrow: {
		lineColor: "#CCD7E6",
		backgroundColor: "#393939",
	},
	org: {
		backgroundColor: "#393939",
	},
};

const common = {
	block: {
		angle: 0,
		lineStyle: "solid",
		textAlign: "center",
		textVAlign: "center",
		lineWidth: 1,
		fontStyle: "normal",
		fontWeight: 400,
		fillOpacity: 1,
	},
	line: {
		lineWidth: 1,
		lineStyle: "solid",
	},
	arrow: {
		lineWidth: 1,
		fillOpacity: 1,
	},
	org: {
		lineColor: "#2d9bf0",
		backgroundColor: "#fff",
	},
};
const skinStyles = { material, mini, flat, compact, contrast };

const fonts = [
	"Arial",
	"Verdana",
	"Helvetica",
	"Tahoma",
	"Trebuchet MS",
	"Times New Roman",
	"Georgia",
	"Garamond",
	"Courier New",
	"Lucida Console",
	"Monaco",
	"Comic Sans MS",
];

export default class Style {
	/**
	 * @constructor
	 * @param {App} app - Jet App instance
	 * @param {object} config - object with app settings
	 */
	constructor(app) {
		this.app = app;

		const style = (this._style = webix.copy(common));
		const skin = skinStyles[webix.skin.$name];
		for (let name in style)
			if (skin[name]) webix.extend(style[name], skin[name], true);

		this._fonts = (fonts.indexOf(style.block.fontFamily) == -1
			? [style.block.fontFamily]
			: []
		).concat(fonts);
		this._arrows = ["", "angle", "triangle", "triangle-fill"];

		//cannot be reset by editor.setValues()
		this._reservedType = {
			templateSvg: 1,
			templateTextStart: 1,
			templateStart: 1,
			templateTextEnd: 1,
			templateEnd: 1,
		};

		this._reservedLink = {
			templateArrow: 1,
			templateLine: 1,
			templateLink: 1,
			templateShadow: 1,
			shadow: 1,
		};
	}

	/**
	 * connect diagram view
	 * @param view {object} diagram view
	 */
	connect(view) {
		this._type = view.type;
		this._linkType = view.linkType;
		this._shapes = this.app.getService("local").shapes();

		this._view = view;
		this._dtype = {
			type: webix.copy(this._type),
			linkType: webix.copy(this._linkType),
		};
	}

	/**
	 * Sets type for data items and links
	 * @param type {object} data type
	 * @param linkType {object} link type
	 */
	setType(type, linkType) {
		const defaults = this._dtype;

		const safeType = {};
		for (let i in type) {
			if (!this._reservedType[i]) safeType[i] = type[i];
		}

		const safeLink = {};
		for (let i in linkType) {
			if (!this._reservedLink[i]) safeLink[i] = linkType[i];
		}

		webix.extend(safeType, defaults.type);
		webix.extend(safeLink, defaults.linkType);

		this._view.type = this._type = safeType;
		this._view.linkType = this._linkType = safeLink;
	}

	/**
	 * Get type object
	 * @returns {object}
	 */
	getType() {
		return this._type;
	}

	/**
	 * Get link type object
	 * @returns {object}
	 */
	getLinkType() {
		return this._linkType;
	}

	/**
	 * Get default style properties
	 * @returns {object}
	 */
	getDefaults() {
		return this._style;
	}

	/**
	 * Get common style values
	 * @returns {object}
	 */
	getCommonValues() {
		return { ...this.getLinkValues(), ...this.getBlockValues() };
	}
	/**
	 * Get block style values
	 * @param item {object} an item object (optional, for common values is not set)
	 * @returns {object}
	 */
	getBlockValues(item) {
		item = item || {};

		let keys = ["height", "width"];
		const defaults = this._style.block;
		if (item.id) {
			keys = keys.concat(["x", "y", "id", "value"]);
			keys = keys.concat(Object.keys(defaults));
		}

		const result = {};
		if (item.type) {
			const type = this.extractTypeValues(item.type);
			webix.extend(result, type);
		}

		keys.forEach(k => {
			let value = item[k];
			if (typeof value == "undefined") value = result[k];
			if (typeof value == "undefined") {
				value = defaults[k];
				if (typeof value == "undefined") value = this._type[k];
			}
			result[k] = value;
		});

		if (result.fontFamily == "Roboto" && result.fontWeight == 500)
			result.fontWeight = "bold";

		if (typeof result.fillOpacity != "undefined") {
			if (!item.type || this._shapes.getItem(item.type).template != "text")
				result.fillOpacity *= 100;
			else result.transparent = result.fillOpacity ? 0 : 1;
		}

		return result;
	}
	/**
	 * Get link style values
	 * @param link {object} a link object (optional, for common values is not set)
	 * @returns {object}
	 */
	getLinkValues(link) {
		link = link || {};

		let keys = ["arrowSize", "arrow", "mode", "backgroundColor"];
		const defaults = this._style.line;
		if (link.id) {
			keys = keys.concat(Object.keys(defaults));
		}

		const result = {};
		keys.forEach(k => {
			let value = link[k];
			if (typeof value == "undefined") {
				value = defaults[k];
				if (typeof value == "undefined") value = this._linkType[k];
			}
			result[k] = value;
		});

		if (link.id && (link.from || link.to || webix.isArray(link.line)))
			result.mode = "";

		let arrow = result.arrow;
		if (
			arrow &&
			result.backgroundColor &&
			this._arrows.indexOf(arrow + "-fill") != -1
		) {
			arrow = arrow + "-fill";
		} else if (arrow && arrow != "triangle") arrow = "angle";

		result.arrow = arrow;
		return result;
	}

	/**
	 * Get an array of font-families (skin font-family is the first)
	 * @returns {Array}
	 */
	getFontFamilies() {
		return this._fonts;
	}
	/**
	 * Get an array of arrow types
	 * @returns {Array}
	 */
	getArrows() {
		return this._arrows;
	}
	/**
	 * Get item type values
	 * @param type {String} item type (shape id)
	 * @returns {Object}
	 */
	extractTypeValues(type) {
		const baseShape = this._shapes.getItem(type);
		if (baseShape) {
			const props = webix.copy(baseShape);
			delete props.id;
			delete props.name;
			delete props.svg;
			delete props.group;

			return props;
		}
		return this._style[type] || {};
	}
}
