export default {
	"Link arrow": "Link arrow",
	"Default link arrow": "Default link arrow",
	"Link mode": "Link mode",
	"Default link mode": "Default link mode",
	Fill: "Fill",
	Size: "Size",
	"Block settings": "Block settings",
	"Default block size": "Default block size",
	Font: "Font",
	"Line style": "Line style",
	"Border style": "Border style",
	Content: "Content",
	None: "None",
	Reset: "Reset",
	Autoplace: "Autoplace",
	Apply: "Apply",
	Background: "Background",
	Transparent: "Transparent",

	// Titles of default groups in the list of shapes
	Block: "Block",
	Flow: "Flow",
	Common: "Common",
	Extra: "Extra",

	// Built-in shapes: block
	Circle: "Circle",
	Dot: "Dot",
	Ellipse: "Ellipse",
	Head: "Head",
	Heptagon: "Heptagon",
	Join: "Join",
	Junction: "Junction",
	Octagon: "Octagon",
	Pentagon: "Pentagon",
	Plus: "Plus",
	Rrect: "Rounded rectangle",
	Rtriangle: "Right triangle",
	Square: "Square",
	Star: "Star",
	Tail: "Tail",
	Trapezoid: "Trapezoid",
	Triangle: "Triangle",
	// Built-in shapes: flow
	Action: "Action",
	Collate: "Collate",
	Connector: "Connector",
	Data: "Data",
	Database: "Database",
	Decision: "Decision",
	Delay: "Delay",
	Display: "Display",
	Document: "Document",

	Input: "Input",
	Internal: "Internal",
	Looplimit: "Loop limit",
	Merge: "Merge",
	Multidoc: "Multiple documents",
	Note: "Note",
	Operation: "Operation",
	Or: "Or",
	Output: "Output",
	Preparation: "Preparation",
	Process: "Process",

	Sdata: "Sequential data",
	Sort: "Sort",
	Start: "Start / End",
	Storage: "Storage",
	Subroutine: "Subroutine",
	Tape: "Tape",
	// Built-in shapes: common
	Default: "Default",
	Org: "Organization",
	Text: "Text",
};
