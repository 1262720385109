import { JetApp, plugins, EmptyRouter } from "webix-jet";
import { createState, link } from "jet-restate";
import Styles from "models/Styles";
import History from "models/History";
import LocalData from "models/LocalData";

import views from "./export_views";
import en from "locales/en";

export class App extends JetApp {
	constructor(config) {
		const state = createState({
			selected: null,
			zoom: 1,
			gridStep: 10,
		});

		config = config || {};

		const defaults = {
			router: EmptyRouter,
			version: VERSION,
			debug: DEBUG,
			start: "/editor",
			params: { state },
			minItemWidth: 10,
			minItemHeight: 10,
		};

		super({ ...defaults, ...config });

		this.use(
			plugins.Locale,
			this.config.locale || {
				lang: "en",
				webix: {
					en: "en-US",
				},
			}
		);

		const dynamic = obj =>
			this.config.override ? this.config.override.get(obj) || obj : obj;

		this.setService("styles", new (dynamic(Styles))(this));
		this.setService("history", new (dynamic(History))(this));
		this.setService("local", new (dynamic(LocalData))(this));
	}

	require(type, name) {
		if (type === "jet-views") return views[name];
		else if (type === "jet-locales") return locales[name];

		return null;
	}

	getState() {
		return this.config.params.state;
	}

	_prepareLinks(data) {
		data = data || [];
		for (let i = 0; i < data.length; i++)
			if (data[i].mode == "child" || data[i].mode == "sibling") {
				data[i].from = data[i].to = "left";
				delete data[i].mode;
			}
		return data;
	}

	setValues(data) {
		this.getService("styles").setType(data.item, data.linkItem);
		this.callEvent("values:defaults", []); //update form

		const local = this.getService("local");

		local.shapes().clearAll();
		local.shapes().parse(data.shapes || []);

		local.links().clearAll();
		local.links().parse(this._prepareLinks(data.links));

		local.data().clearAll();
		local.data().importData(data.data);

		this.getService("history").track();
	}

	getValues() {
		const local = this.getService("local");
		const styles = this.getService("styles");

		return {
			data: local.data().serialize(),
			links: local.links().serialize(),
			shapes: local.shapes().serialize(),
			linkItem: styles.getLinkValues(),
			item: styles.getBlockValues(),
		};
	}
}

webix.protoUI(
	{
		name: "diagram-editor",
		app: App,
		defaults: {
			borderless: true,
		},
		$init: function() {
			this.name = "diagram-editor";
			this.$view.className += " webix_diagram_editor";

			const state = this.$app.getState();

			for (let key in state) {
				link(state, this.config, key);
			}

			this.$app.attachEvent("diagram:beforedrag", (ctx, e) =>
				this.callEvent("onBeforeDrag", [ctx, e])
			);
			this.$app.attachEvent("diagram:beforedrop", (ctx, e) =>
				this.callEvent("onBeforeDrop", [ctx, e])
			);
		},
		getState() {
			return this.$app.getState();
		},
		getService(name) {
			return this.$app.getService(name);
		},
		setValues(data) {
			return this.$app.setValues(data);
		},
		getValues() {
			return this.$app.getValues();
		},
	},
	webix.ui.jetapp
);

// re-export for customization
const services = { Styles };
const locales = { en };
export { views, locales, services };
