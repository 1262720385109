import { JetView } from "webix-jet";
import "../../helpers/icontoggle";

export default class ContextView extends JetView {
	config() {
		const ui = {
			view: "window",
			head: false,
			autofit: false,
			body: {
				padding: 4,
				margin: 4,
				cols: [
					{
						view: "icon",
						batch: "all",
						icon: "dgi-content-copy",
						click: () => this.CallAction("clone"),
					},
					{
						view: "diagram-icon-toggle",
						batch: "all",
						localId: "mode",
						on: {
							onItemClick: () =>
								this.CallAction("mode", this.$$("mode").getValue()),
						},
					},
					{
						view: "icon",
						icon: "wxi-trash",
						click: () => this.CallAction("remove"),
					},
				],
			},
		};
		return ui;
	}

	init(view) {
		this.Window = view;
	}

	/**
	 * Tell the app about user interaction using the context menu
	 */
	CallAction(name, value) {
		const obj = { name, value };
		this.app.callEvent("context:action", [obj]);
	}

	/**
	 * Shows a context menu depending on the object being clicked
	 * @param obj {Object} the data object
	 * @param isLink {Boolean} defines it as a link or block
	 * @param c {Object} configuration object with additional information
	 * @param zoom {number} current zoom
	 */
	Show(obj, isLink, c, zoom) {
		this.Window.getBody().showBatch(isLink ? "none" : "all");

		let pos;
		if (isLink) {
			if (c.e) {
				const offset = webix.html.offset(c.view);
				pos = webix.html.pos(c.e);
				pos = {
					x: pos.x - offset.x + c.scroll.x + 16,
					y: pos.y - offset.y + c.scroll.y + 4,
				};
			} else {
				const p = c.node.points;
				const index = p.length == 2 ? 1 : p.length - 1;
				pos = {
					x: p[index].x * zoom + c.px - 8 - this.Window.$width,
					y: p[index].y * zoom + c.py + 4,
				};
			}
		} else {
			this.$$("mode").setValue(c.value, "auto");
			const p = this.GetBlockPosition(obj, c);
			pos = {
				x: Math.max(2, p.x * zoom + c.px),
				y: Math.max(2, p.y * zoom + c.py - p.dy),
			};
		}
		this.Window.show(pos);
	}

	/**
	 * Hides a context menu
	 */
	Hide() {
		this.Window.hide();
	}

	/**
	 * Calculate context menu position based on block position and angle
	 * @param obj {Object} the data object
	 * @param c {Object} configuration object with additional information
	 * @return {Object} calculated position
	 */
	GetBlockPosition(obj, c) {
		const result = {
			x: obj.x - 5,
			y: obj.y - 5,
			dy: this.Window.$height + 8,
		};

		if (c.angle) {
			let width = parseInt(c.node.style.width) / 2;
			let height = parseInt(c.node.style.height) / 2;

			const cx = obj.x + width;
			const cy = obj.y + height;
			if (Math.floor(c.angle / 90) % 2) [width, height] = [height, width];

			const r = Math.sqrt(Math.pow(width + 5, 2) + Math.pow(height + 5, 2));
			let angle =
				Math.PI * 1.5 -
				Math.atan(width / height) +
				((c.angle % 90) * Math.PI) / 180;

			if (Math.floor((c.angle % 360) / 90) == 3) {
				angle += Math.PI;
				result.dy = -8;
			}

			result.x = cx + r * Math.cos(angle);
			result.y = cy + r * Math.sin(angle);
		}
		return result;
	}
}
